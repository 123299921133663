import React, { useEffect, useContext, useState } from "react";
import ProjectTable from "../Components/ProjectTable";
import { useNavigate } from "react-router-dom";
import { AppContext, useUIContext } from "../Contexts/AppContextProvider";
import { ProjectService } from "../WebApiServices/Project.service";
import { HTTPResponse, ROLE_TYPE } from "../Helpers/Enums";
import {
  AddLocalStorageData,
  GetLocalStorageData,
  RemoveLocalStorageData,
  changePageTitle
} from "../Helpers/Utilities";
import BreadcrumbComponent from "../Components/BreadcrumbComponent";
import { ManageUsersService } from "../WebApiServices/ManageUsers.Service";
import { UserRolesPermission } from "../WebApiServices/RolesPermission.service";

const Projects = () => {
  useEffect(() => {
    RemoveLocalStorageData("key");
    RemoveLocalStorageData("newlySelectedProjects");
    RemoveLocalStorageData("activeTab")
    RemoveLocalStorageData("selectedSprint")

  });
  const { LogOut } = useUIContext();
  const { ToggleLoader } = useContext(AppContext);
  const [projectSearch, setProjectSearch] = useState("");
  const [projectData, setProjectData] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [projectName, setProjectName] = useState("test");
  const roleId = Number(GetLocalStorageData("roleID"));
  const [existingProjectNames, setExistingProjectNames] = useState("");
  const [existingKey, setExistingkey] = useState("");
  const [load, setLoad] = useState(false);
  const [isProjectDeleted, SetIsProjectDeleted] = useState(false)
  const [lastVisitedList, setLastVisitedList] = useState([]);

  //SYJ - User Roles Permission
  const [adminPermission, setAdminPermission] = useState({
    ManageEmp: {
      add: 0,
      edit: 0,
      view: 0,
      delete: 0,
      addOther: 0,
      editOther: 0,
      viewOther: 0,
      deleteOther: 0
    },
    ManageRolesPermission: {
      add: 0,
      edit: 0,
      view: 0,
      delete: 0,
      addOther: 0,
      editOther: 0,
      viewOther: 0,
      deleteOther: 0
    },
    ManageProject: {
      add: 0,
      edit: 0,
      view: 0,
      delete: 0,
      addOther: 0,
      editOther: 0,
      viewOther: 0,
      deleteOther: 0
    },
    TimeLog: {
      add: 0,
      edit: 0,
      view: 0,
      delete: 0,
      addOther: 0,
      editOther: 0,
      viewOther: 0,
      deleteOther: 0
    },
    MyTask: {
      add: 0,
      edit: 0,
      view: 0,
      delete: 0,
      addOther: 0,
      editOther: 0,
      viewOther: 0,
      deleteOther: 0
    }
  });

  const [projectLeadPermission, setProjectLeadPermission] = useState({
    ManageEmp: {
      add: 0,
      edit: 0,
      view: 0,
      delete: 0,
      addOther: 0,
      editOther: 0,
      viewOther: 0,
      deleteOther: 0
    },
    ManageRolesPermission: {
      add: 0,
      edit: 0,
      view: 0,
      delete: 0,
      addOther: 0,
      editOther: 0,
      viewOther: 0,
      deleteOther: 0
    },
    ManageProject: {
      add: 0,
      edit: 0,
      view: 0,
      delete: 0,
      addOther: 0,
      editOther: 0,
      viewOther: 0,
      deleteOther: 0
    },
    TimeLog: {
      add: 0,
      edit: 0,
      view: 0,
      delete: 0,
      addOther: 0,
      editOther: 0,
      viewOther: 0,
      deleteOther: 0
    },
    MyTask: {
      add: 0,
      edit: 0,
      view: 0,
      delete: 0,
      addOther: 0,
      editOther: 0,
      viewOther: 0,
      deleteOther: 0
    }
  });

  const [employeePermission, setEmployeePermission] = useState({
    ManageEmp: {
      add: 0,
      edit: 0,
      view: 0,
      delete: 0,
      addOther: 0,
      editOther: 0,
      viewOther: 0,
      deleteOther: 0
    },
    ManageRolesPermission: {
      add: 0,
      edit: 0,
      view: 0,
      delete: 0,
      addOther: 0,
      editOther: 0,
      viewOther: 0,
      deleteOther: 0
    },
    ManageProject: {
      add: 0,
      edit: 0,
      view: 0,
      delete: 0,
      addOther: 0,
      editOther: 0,
      viewOther: 0,
      deleteOther: 0
    },
    TimeLog: {
      add: 0,
      edit: 0,
      view: 0,
      delete: 0,
      addOther: 0,
      editOther: 0,
      viewOther: 0,
      deleteOther: 0
    },
    MyTask: {
      add: 0,
      edit: 0,
      view: 0,
      delete: 0,
      addOther: 0,
      editOther: 0,
      viewOther: 0,
      deleteOther: 0
    }
  });

  const navigate = useNavigate();
  const userId = GetLocalStorageData("userId");
  const handleSearchInputChange = (e) => {
    const searchProject = e.target.value;
    setProjectSearch(searchProject);
  };
  /* <summary>
  date: 09-02-2024
  Name: MS
  description: Clear input field onclick
  <summary>*/
  const handleClearInput = () => {
    setProjectSearch("");

  }
  const GetLastVisitedProjectIds = async () => {
    const response = await ProjectService.AddLastVisitedProject(userId, 0);

    if (response.status === HTTPResponse.OK) {
      setLastVisitedList(response.data.projectIds);
    }
  }
  const GetAllProject = async () => {
    ToggleLoader(true);

    let response = await ProjectService.GetAllProjects();

    const datas = await ManageUsersService.GetProfileData(userId);
    if (datas.data && datas.data.length > 0) {
      const firstData = datas.data[0];
      AddLocalStorageData("imagePath", firstData.userPic);
    }
    const projectNames = response.data && response.data.length > 0 && response.data.map((project) => ({
      id: project.id,
      name: project.name
    }));

    const projectKey = response.data && response.data.length > 0 && response.data.map((project) => ({
      id: project.id,
      name: project.key
    }));

    setExistingProjectNames(projectNames);
    setExistingkey(projectKey);

    if (response.status === HTTPResponse.OK) {

      if (roleId == ROLE_TYPE.ADMIN) {
        setProjectData(response.data)
      }
      else {
        const userID = Number(GetLocalStorageData("userId"))
        const assignedProjects = response.data.filter((project) =>
          project.employeeList && project.employeeList.includes(userID) && project.status == 1
        );
        if (roleId == ROLE_TYPE.LEAD) {
          const projectNames = assignedProjects.map(project => project.name);
          AddLocalStorageData("ProjectsLeading", JSON.stringify(projectNames))
        }


        setProjectData(assignedProjects);
      }

      ToggleLoader(false);
    }
    // if (response.status === HTTPResponse.Unauthorized) {
    //   LogOut();
    // }
  };


  useEffect(() => {
    changePageTitle("Projects");
    RemoveLocalStorageData("newlySelectedEmployee");
    RemoveLocalStorageData("ProjectAddDetails");
  }, [])
  useEffect(() => {
    GetLastVisitedProjectIds();
    GetAllProject();
    GetAllRolesPermission();
  }, [load]);
  const handleStatusChange = () => {
    setLoad(!load);
  }


  const onDeleteProject = () => {
    setLoad(!load);
  }


  useEffect(() => { }, [existingProjectNames], [existingKey]);

  // useEffect(() => {
  //   const filteredProjects = projectData.filter(
  //     (item) =>
  //       (projectSearch.length > 0 &&
  //         item.name
  //           .toLowerCase()
  //           .includes(projectSearch.toLocaleLowerCase())) ||
  //       item.clientName
  //         .toLowerCase()
  //         .includes(projectSearch.toLocaleLowerCase()) ||
  //       item.projectLead
  //         .toLowerCase()
  //         .includes(projectSearch.toLocaleLowerCase()) ||
  //       item.key.toLowerCase().includes(projectSearch.toLocaleLowerCase())
  //   );
  //   setFilterData(filteredProjects);

  // }, [projectSearch, projectData]);
  useEffect(() => {
    // Retrieve the list of last visited project IDs from local storage

    //const lastVisitedProjectIds =GetLocalStorageData("lastVisitedProjectIds")?  JSON.parse(GetLocalStorageData("lastVisitedProjectIds")) : [];
    const lastVisitedProjectIds = lastVisitedList ? lastVisitedList : []
    // Filter the project data based on the search term
    const filteredProjects = projectData.filter((item) =>
      projectSearch.length > 0
        ? item.name.toLowerCase().includes(projectSearch.toLowerCase()) ||
        item.clientName.toLowerCase().includes(projectSearch.toLowerCase()) ||
        item.projectLead.toLowerCase().includes(projectSearch.toLowerCase()) ||
        item.key.toLowerCase().includes(projectSearch.toLowerCase())
        : true
    );
    const lastVisited = [];
    const remainingProjects = [];

    filteredProjects.forEach((project) => {
      if (lastVisitedProjectIds.includes(project.id)) {
        lastVisited.push(project);
      } else {
        remainingProjects.push(project);
      }
    });

    // Sort last visited projects based on their order in local storage
    lastVisited.sort(
      (a, b) => lastVisitedProjectIds.indexOf(a.id) - lastVisitedProjectIds.indexOf(b.id)
    );

    // Combine the sorted last visited projects with the remaining projects
    const sortedAndFilteredProjects = [...lastVisited, ...remainingProjects];

    // Update the state with the sorted and filtered projects
    setFilterData(sortedAndFilteredProjects);
  }, [projectSearch, projectData]);


  const getProjectName = (name) => {
    setProjectName(name);
  };

  const handleAddProject = () => {
    navigate("/AddProject", {
      state: { existingProjects: existingProjectNames, Key: existingKey },
    });
  };


  //Set Roles Permission
  const GetAllRolesPermission = async () => {
    try {
      ToggleLoader(true);
      //Default Array llist to filter data
      let adminList = [];
      let adminOtherList = [];
      let leadList = [];
      let leadOtherList = [];
      let employeeList = [];
      let employeeOtherList = [];
      const responseDefaultRP = await UserRolesPermission.GetDefaultRolePermissions();
      const responseUserSaveRP = await UserRolesPermission.GetUserSavedRolePermissions();

      // Function to get the list of role IDs that exist in the user saved role permissions data
      const existingRoleIds = responseUserSaveRP.data.map(item => item.roleId);


      // Bind data based on the existing role IDs
      if (existingRoleIds.includes(1)) {
        adminList = responseUserSaveRP.data.filter(item => item.roleId === 1 && item.staticRolePermissionValueId === 1);
        adminOtherList = responseUserSaveRP.data.filter(item => item.roleId === 1 && item.staticRolePermissionValueId === 2);
      }
      if (existingRoleIds.includes(2)) {
        employeeList = responseUserSaveRP.data.filter(item => item.roleId === 2 && item.staticRolePermissionValueId === 1);
        employeeOtherList = responseUserSaveRP.data.filter(item => item.roleId === 2 && item.staticRolePermissionValueId === 2);
      }
      if (existingRoleIds.includes(3)) {
        leadList = responseUserSaveRP.data.filter(item => item.roleId === 3 && item.staticRolePermissionValueId === 1);
        leadOtherList = responseUserSaveRP.data.filter(item => item.roleId === 3 && item.staticRolePermissionValueId === 2);
      }

      // If no role IDs 1, 2, or 3 data is present, bind the default list
      if (adminList.length === 0) {
        adminList = responseDefaultRP.data.filter(item => item.roleId === 1 && item.staticRolePermissionValueId === 1);
      }
      if (employeeList.length === 0) {
        employeeList = responseDefaultRP.data.filter(item => item.roleId === 2 && item.staticRolePermissionValueId === 1);
      }
      if (leadList.length === 0) {
        leadList = responseDefaultRP.data.filter(item => item.roleId === 3 && item.staticRolePermissionValueId === 1);
      }

      if (adminList.length > 0) {
        // Update the adminPermission state with the filtered admin list
        setAdminPermission({
          ManageEmp: {
            add: adminList.some(item => item.meadd === 0) ? 0 : 1,
            edit: adminList.some(item => item.meedit === 0) ? 0 : 1,
            view: adminList.some(item => item.meview === 0) ? 0 : 1,
            delete: adminList.some(item => item.medelete === 0) ? 0 : 1,
          },
          ManageRolesPermission: {
            add: adminList.some(item => item.mrpadd === 0) ? 0 : 1,
            edit: adminList.some(item => item.mrpedit === 0) ? 0 : 1,
            view: adminList.some(item => item.mrpview === 0) ? 0 : 1,
            delete: adminList.some(item => item.mrpdelete === 0) ? 0 : 1,
          },
          ManageProject: {
            add: adminList.some(item => item.mpadd === 0) ? 0 : 1,
            edit: adminList.some(item => item.mpedit === 0) ? 0 : 1,
            view: adminList.some(item => item.mpview === 0) ? 0 : 1,
            delete: adminList.some(item => item.mpdelete === 0) ? 0 : 1,
          },
          TimeLog: {
            add: adminList.some(item => item.tladd === 0) ? 0 : 1,
            edit: adminList.some(item => item.tledit === 0) ? 0 : 1,
            view: adminList.some(item => item.tlview === 0) ? 0 : 1,
            delete: adminList.some(item => item.tldelete === 0) ? 0 : 1,
          },
          MyTask: {
            add: adminList.some(item => item.mtadd === 0) ? 0 : 1,
            edit: adminList.some(item => item.mtedit === 0) ? 0 : 1,
            view: adminList.some(item => item.mtview === 0) ? 0 : 1,
            delete: adminList.some(item => item.mtdelete === 0) ? 0 : 1,
          }
        });
      }

      if (leadList.length > 0) {
        // Update the adminPermission state with the filtered admin list
        setProjectLeadPermission({
          ManageEmp: {
            add: leadList.some(item => item.meadd === 0) ? 0 : 1,
            edit: leadList.some(item => item.meedit === 0) ? 0 : 1,
            view: leadList.some(item => item.meview === 0) ? 0 : 1,
            delete: leadList.some(item => item.medelete === 0) ? 0 : 1,
          },
          ManageRolesPermission: {
            add: leadList.some(item => item.mrpadd === 0) ? 0 : 1,
            edit: leadList.some(item => item.mrpedit === 0) ? 0 : 1,
            view: leadList.some(item => item.mrpview === 0) ? 0 : 1,
            delete: leadList.some(item => item.mrpdelete === 0) ? 0 : 1,
          },
          ManageProject: {
            add: leadList.some(item => item.mpadd === 0) ? 0 : 1,
            edit: leadList.some(item => item.mpedit === 0) ? 0 : 1,
            view: leadList.some(item => item.mpview === 0) ? 0 : 1,
            delete: leadList.some(item => item.mpdelete === 0) ? 0 : 1,
          },
          TimeLog: {
            add: leadList.some(item => item.tladd === 0) ? 0 : 1,
            edit: leadList.some(item => item.tledit === 0) ? 0 : 1,
            view: leadList.some(item => item.tlview === 0) ? 0 : 1,
            delete: leadList.some(item => item.tldelete === 0) ? 0 : 1,
          },
          MyTask: {
            add: leadList.some(item => item.mtadd === 0) ? 0 : 1,
            edit: leadList.some(item => item.mtedit === 0) ? 0 : 1,
            view: leadList.some(item => item.mtview === 0) ? 0 : 1,
            delete: leadList.some(item => item.mtdelete === 0) ? 0 : 1,
          }
        });
      }

      if (employeeList.length > 0) {
        // Update the adminPermission state with the filtered admin list
        setEmployeePermission({
          ManageEmp: {
            add: employeeList.some(item => item.meadd === 0) ? 0 : 1,
            edit: employeeList.some(item => item.meedit === 0) ? 0 : 1,
            view: employeeList.some(item => item.meview === 0) ? 0 : 1,
            delete: employeeList.some(item => item.medelete === 0) ? 0 : 1,
          },
          ManageRolesPermission: {
            add: employeeList.some(item => item.mrpadd === 0) ? 0 : 1,
            edit: employeeList.some(item => item.mrpedit === 0) ? 0 : 1,
            view: employeeList.some(item => item.mrpview === 0) ? 0 : 1,
            delete: employeeList.some(item => item.mrpdelete === 0) ? 0 : 1,
          },
          ManageProject: {
            add: employeeList.some(item => item.mpadd === 0) ? 0 : 1,
            edit: employeeList.some(item => item.mpedit === 0) ? 0 : 1,
            view: employeeList.some(item => item.mpview === 0) ? 0 : 1,
            delete: employeeList.some(item => item.mpdelete === 0) ? 0 : 1,
          },
          TimeLog: {
            add: employeeList.some(item => item.tladd === 0) ? 0 : 1,
            edit: employeeList.some(item => item.tledit === 0) ? 0 : 1,
            view: employeeList.some(item => item.tlview === 0) ? 0 : 1,
            delete: employeeList.some(item => item.tldelete === 0) ? 0 : 1,
          },
          MyTask: {
            add: employeeList.some(item => item.mtadd === 0) ? 0 : 1,
            edit: employeeList.some(item => item.mtedit === 0) ? 0 : 1,
            view: employeeList.some(item => item.mtview === 0) ? 0 : 1,
            delete: employeeList.some(item => item.mtdelete === 0) ? 0 : 1,
          }
        });
      }

      if (adminOtherList.length > 0) {
        // Update the adminPermission state with the filtered admin list
        setAdminPermission(prevState => ({
          ...prevState,
          ManageEmp: {
            ...prevState.ManageEmp,
            addOther: adminOtherList.some(item => item.meadd === 0) ? 0 : 1,
            editOther: adminOtherList.some(item => item.meedit === 0) ? 0 : 1,
            viewOther: adminOtherList.some(item => item.meview === 0) ? 0 : 1,
            deleteOther: adminOtherList.some(item => item.medelete === 0) ? 0 : 1,
          },
          ManageRolesPermission: {
            ...prevState.ManageRolesPermission,
            addOther: adminOtherList.some(item => item.mrpadd === 0) ? 0 : 1,
            editOther: adminOtherList.some(item => item.mrpedit === 0) ? 0 : 1,
            viewOther: adminOtherList.some(item => item.mrpview === 0) ? 0 : 1,
            deleteOther: adminOtherList.some(item => item.mrpdelete === 0) ? 0 : 1,
          },
          ManageProject: {
            ...prevState.ManageProject,
            addOther: adminOtherList.some(item => item.mpadd === 0) ? 0 : 1,
            editOther: adminOtherList.some(item => item.mpedit === 0) ? 0 : 1,
            viewOther: adminOtherList.some(item => item.mpview === 0) ? 0 : 1,
            deleteOther: adminOtherList.some(item => item.mpdelete === 0) ? 0 : 1,
          },
          TimeLog: {
            ...prevState.TimeLog,
            addOther: adminOtherList.some(item => item.tladd === 0) ? 0 : 1,
            editOther: adminOtherList.some(item => item.tledit === 0) ? 0 : 1,
            viewOther: adminOtherList.some(item => item.tlview === 0) ? 0 : 1,
            deleteOther: adminOtherList.some(item => item.tldelete === 0) ? 0 : 1,
          },
          MyTask: {
            ...prevState.MyTask,
            addOther: adminOtherList.some(item => item.mtadd === 0) ? 0 : 1,
            editOther: adminOtherList.some(item => item.mtedit === 0) ? 0 : 1,
            viewOther: adminOtherList.some(item => item.mtview === 0) ? 0 : 1,
            deleteOther: adminOtherList.some(item => item.mtdelete === 0) ? 0 : 1,
          }
        }));
      }

      if (leadOtherList.length > 0) {
        // Update the adminPermission state with the filtered admin list
        setProjectLeadPermission(prevState => ({
          ...prevState,
          ManageEmp: {
            ...prevState.ManageEmp,
            addOther: leadOtherList.some(item => item.meadd === 0) ? 0 : 1,
            editOther: leadOtherList.some(item => item.meedit === 0) ? 0 : 1,
            viewOther: leadOtherList.some(item => item.meview === 0) ? 0 : 1,
            deleteOther: leadOtherList.some(item => item.medelete === 0) ? 0 : 1,
          },
          ManageRolesPermission: {
            ...prevState.ManageRolesPermission,
            addOther: leadOtherList.some(item => item.mrpadd === 0) ? 0 : 1,
            editOther: leadOtherList.some(item => item.mrpedit === 0) ? 0 : 1,
            viewOther: leadOtherList.some(item => item.mrpview === 0) ? 0 : 1,
            deleteOther: leadOtherList.some(item => item.mrpdelete === 0) ? 0 : 1,
          },
          ManageProject: {
            ...prevState.ManageProject,
            addOther: leadOtherList.some(item => item.mpadd === 0) ? 0 : 1,
            editOther: leadOtherList.some(item => item.mpedit === 0) ? 0 : 1,
            viewOther: leadOtherList.some(item => item.mpview === 0) ? 0 : 1,
            deleteOther: leadOtherList.some(item => item.mpdelete === 0) ? 0 : 1,
          },
          TimeLog: {
            ...prevState.TimeLog,
            addOther: leadOtherList.some(item => item.tladd === 0) ? 0 : 1,
            editOther: leadOtherList.some(item => item.tledit === 0) ? 0 : 1,
            viewOther: leadOtherList.some(item => item.tlview === 0) ? 0 : 1,
            deleteOther: leadOtherList.some(item => item.tldelete === 0) ? 0 : 1,
          },
          MyTask: {
            ...prevState.MyTask,
            addOther: leadOtherList.some(item => item.mtadd === 0) ? 0 : 1,
            editOther: leadOtherList.some(item => item.mtedit === 0) ? 0 : 1,
            viewOther: leadOtherList.some(item => item.mtview === 0) ? 0 : 1,
            deleteOther: leadOtherList.some(item => item.mtdelete === 0) ? 0 : 1,
          }
        }));
      }

      if (employeeOtherList.length > 0) {
        // Update the adminPermission state with the filtered admin list
        setEmployeePermission(prevState => ({
          ManageEmp: {
            ...prevState.ManageEmp,
            addOther: employeeOtherList.some(item => item.meadd === 0) ? 0 : 1,
            editOther: employeeOtherList.some(item => item.meedit === 0) ? 0 : 1,
            viewOther: employeeOtherList.some(item => item.meview === 0) ? 0 : 1,
            deleteOther: employeeOtherList.some(item => item.medelete === 0) ? 0 : 1,
          },
          ManageRolesPermission: {
            ...prevState.ManageRolesPermission,
            addOther: employeeOtherList.some(item => item.mrpadd === 0) ? 0 : 1,
            editOther: employeeOtherList.some(item => item.mrpedit === 0) ? 0 : 1,
            viewOther: employeeOtherList.some(item => item.mrpview === 0) ? 0 : 1,
            deleteOther: employeeOtherList.some(item => item.mrpdelete === 0) ? 0 : 1,
          },
          ManageProject: {
            ...prevState.ManageProject,
            addOther: employeeOtherList.some(item => item.mpadd === 0) ? 0 : 1,
            editOther: employeeOtherList.some(item => item.mpedit === 0) ? 0 : 1,
            viewOther: employeeOtherList.some(item => item.mpview === 0) ? 0 : 1,
            deleteOther: employeeOtherList.some(item => item.mpdelete === 0) ? 0 : 1,
          },
          TimeLog: {
            ...prevState.TimeLog,
            addOther: employeeOtherList.some(item => item.tladd === 0) ? 0 : 1,
            editOther: employeeOtherList.some(item => item.tledit === 0) ? 0 : 1,
            viewOther: employeeOtherList.some(item => item.tlview === 0) ? 0 : 1,
            deleteOther: employeeOtherList.some(item => item.tldelete === 0) ? 0 : 1,
          },
          MyTask: {
            ...prevState.MyTask,
            addOther: employeeOtherList.some(item => item.mtadd === 0) ? 0 : 1,
            editOther: employeeOtherList.some(item => item.mtedit === 0) ? 0 : 1,
            viewOther: employeeOtherList.some(item => item.mtview === 0) ? 0 : 1,
            deleteOther: employeeOtherList.some(item => item.mtdelete === 0) ? 0 : 1,
          }
        }));
      }

      ToggleLoader(false);

    } catch (error) {
    }
  };



  return (
    <>
      {/* <summary>
        date: 19-10-2023
        Name: NGG
        description: added breadcrumb component for navigation}
        <summary>*/}

      <section className="breadcrumb-block">
        <BreadcrumbComponent /></section>
      {/* <section className="title-block">
        <div className="container">
          <div className="title-f-box">
            <div className="title-banner">
              <h3 className="page-title">
                Manage Projects
              </h3>
            </div>           
          </div>
        </div>
      </section> */}
      <main className="main-body-content-project custom-project-height" style={{ paddingTop: 0 }}>
        <section>
          <div className="container-fluid">
            <div
              className="row "
              style={{ display: "flex", justifyContent: "space-between", marginBlock: "9px" }}
            >
              <div className="col-8 col-md-6 col-lg-3">
                {/* <label>
                  Search <span className="isRequiredField"></span>
                </label> */}
                <div className="searchBar">
                  <input
                    className="searchQueryInput-class"
                    id="searchQueryInput"
                    type="text"
                    name="searchQueryInput"
                    placeholder="Search Project"
                    value={projectSearch}
                    onChange={handleSearchInputChange}
                  />
                  <button
                    id="searchQuerySubmit"
                    type="submit"
                    name="searchQuerySubmit"
                  >
                    {projectSearch ? (
                      <img
                        src="Assets/images/removeIcon.png"
                        onClick={handleClearInput}
                        width="20px"
                        style={{ cursor: 'pointer' }}
                        alt="Clear"
                      />
                    ) : (
                      <img
                        src="Assets/images/Icon feather-search.png"
                        width="20px"
                        style={{ cursor: 'pointer' }}
                        alt="Search"
                      />
                    )}
                  </button>
                </div>
              </div>
            </div>

            <div className="floating-btn-myprojects">
              {(roleId === ROLE_TYPE.ADMIN || roleId !== ROLE_TYPE.ADMIN && adminPermission.ManageProject.addOther === 1 || roleId === ROLE_TYPE.EMPLOYEE && employeePermission.ManageProject.add === 1
                || roleId === ROLE_TYPE.LEAD && projectLeadPermission.ManageProject.add === 1 || roleId === ROLE_TYPE.EMPLOYEE && projectLeadPermission.ManageProject.addOther === 1) && (
                  <button
                    className="add-project-btn "
                    onClick={handleAddProject}
                    style={{ border: "2px solid #332150" }}
                  >
                    Add Project
                  </button>
                )}
            </div>
            <div className="project-tab table-responsive " style={{ overflowY: 'hidden' }}>
              <ProjectTable
                onChange={handleStatusChange}
                projectData={filterData}
                getProjectName={getProjectName}
                onDeleteProject={onDeleteProject}
                adminPermission={adminPermission}
                leadPermission={projectLeadPermission}
                empPermission={employeePermission}
              />
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default Projects;
