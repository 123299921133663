import React, { useState, useEffect, useCallback } from "react";
import { SprintOverviewService } from "../../WebApiServices/SprintOverview.service";
import ErrorField from "../../Components/ErrorField";
import { parseISO } from "date-fns";

import {
  CommonErrorMessages,
  Sprint,
  ToastMessages,
} from "../../Helpers/HelperText";
import { isStringEmpty } from "../../Helpers/Utilities";
import moment from "moment";
import { AppContext, useUIContext } from "../../Contexts/AppContextProvider";
import {
  DefaultAGGridProps,
  HTTPResponse,
  MaxCharacterCount,
  MonthNames,
  ToastMessageType,
} from "../../Helpers/Enums";
import DatePicker from "react-datepicker";

import { useContext } from "react";
import { SprintService } from "../../WebApiServices/Sprint.service";
import AGGrid from "../../Components/AGGrid";
import hi from "date-fns/esm/locale/hi/index.js";
const CreateSprint = ({
  isOpen,
  closeModal,
  projectId,
  name,
  sprintID,
  sprintActionItemCount,
  sprintInputName,
  sprintStartDate,
  sprintEndDate,
  userID,
  isSprintEdit,
  cancelClick,id
}) => {
  const [gridData, setGridData] = useState([]);
  const { LogOut } = useUIContext();
  const { ShowToastMessage, ToggleLoader } = useContext(AppContext);
  const [sprintName, setSprintName] = useState(sprintInputName);
  const [release, setRelease] = useState(1); // Initialize with default value
  // Get today's date in the format YYYY-MM-DD
  const today = new Date();
  const [error, setError] = useState({
    sprintName: false,
    release: false,
    startDate: false,
    endDate: false,
    sprintEmpty: false,
  });

  const [activeTab, setActiveTab] = useState("Details");

  const [startDate, setStartDate] = useState(null);

  const [endDate, setEndDate] = useState(null);
  const [storeSprintReleaseData, setStoreSprintReleaseDate] = useState([]);
  const [validationError, setValidationError] = useState(""); // For displaying validation errors
  const [selectedReleaseId, setSelectedReleaseId] = useState(null);
  useEffect(() => {
    if (sprintStartDate) {
      setStartDate(parseISO(sprintStartDate));

    }
    if(id){
      setSelectedReleaseId(id)
    }
  }, [sprintStartDate,id]);

  useEffect(() => {
    if (sprintEndDate) {
      setEndDate(parseISO(sprintEndDate));
    }
  }, [sprintEndDate]);

  // Event handlers to update state variables
  const handleSprintNameChange = (e) => {
    setSprintName(e.target.value);
    setError((prev) => ({
      ...prev,
      sprintName: false,
    }));
  };
  const handleReleaseSelect = (releaseId) => {
    setSelectedReleaseId(releaseId);
    setError((prev) => ({
      ...prev,
      release: false,
    }));
  };
  const handleStartDateChange = (date) => {
    setStartDate(date);
    setError((prev) => ({
      ...prev,
      startDate: false,
    }));
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
    setError((prev) => ({
      ...prev,
      endDate: false,
    }));
  };

  // Reset validation errors when modal is closed
  useEffect(() => {
    if (!isOpen) {
      setValidationError("");
      setStartDate("");
      setEndDate("");
      setSelectedReleaseId(null)
      setError((prev) => ({
        ...prev,
        sprintName: false,
        release: false,
        startDate: false,
        endDate: false,
        sprintEmpty: false,
      }));
    }
  }, [isOpen, sprintActionItemCount]);

  useEffect(() => {
    fetchReleasedata();
  }, []);
  useEffect(() => {
    if (
      sprintInputName !== null &&
      sprintInputName !== undefined &&
      sprintInputName != ""
    ) {
      setSprintName(sprintInputName);
    }
  }, [sprintID, sprintInputName]);
  // sprintID &&
  const fetchReleasedata = async () => {
    try {
      const releaseData = await SprintOverviewService.GetSprintReleaseData(
        projectId
      );

      if (releaseData.status === HTTPResponse.Unauthorized) {
        LogOut();
      }
      const data = releaseData.data || [];
      setStoreSprintReleaseDate(releaseData.data);
      if (data.length > MaxCharacterCount.Empty && !id ) {
        setSelectedReleaseId(data[0].releaseId);
      } else {
        setSelectedReleaseId(null);
      }
    } catch (error) {
      ShowToastMessage(ToastMessages.Fail, ToastMessageType.Fail);
    }
  };

  const handleStartSprintClick = async () => {
    try {
      let start_Date = startDate;
      let end_Date = endDate;
      if (start_Date) {
        start_Date.setHours(23, 59, 59, 999);
      }
      if (end_Date) {
        end_Date.setHours(23, 59, 59, 999);
      }
      // Check if the start and end dates are less than today
      let startDateIsBeforeToday = moment(startDate).isBefore(
        moment().startOf("day")
      );
      let endDateIsBeforeStartDate = moment(endDate).isBefore(
        moment(startDate)
      );

      setError((prev) => ({
        ...prev,
        sprintName: isStringEmpty(sprintName),
        startDate: start_Date === null || start_Date === undefined,
        endDate: end_Date === null || end_Date === undefined,
        sprintEmpty: sprintActionItemCount === 0 && !isSprintEdit,
      }));
      if (sprintActionItemCount === MaxCharacterCount.Empty && !isSprintEdit) {
        setValidationError(true);
      }
      // Check if there are any validation errors
      if (
        isStringEmpty(sprintName) ||
        start_Date === null ||
        start_Date === undefined ||
        end_Date === null ||
        end_Date === undefined ||
        Number(selectedReleaseId) === MaxCharacterCount.Empty ||
        (sprintActionItemCount === MaxCharacterCount.Empty && !isSprintEdit)
      ) {
        return; // Do not proceed with the API call or modal close
      }
      let edit = isSprintEdit;
      const sprintData = {
        projectId,
        sprintName,
        selectedReleaseId,
        start_Date,
        end_Date,
        sprintID,
        edit,userID
      };

      const resp = await SprintOverviewService.StartSprint(sprintData);
      if (resp.status === HTTPResponse.Unauthorized) {
        LogOut();
      }
    } catch (error) {
      ShowToastMessage(ToastMessages.Fail, ToastMessageType.Fail);
    }

    closeModal();
  };

  // const AddEditSprint = async () => {
  //   try {
  //     debugger
  //     ToggleLoader(true);
  //     if (startDate != "") {
  //       startDate.setHours(23, 59, 59, 999);
  //     }
  //     if (endDate != 0) {
  //       endDate.setHours(23, 59, 59, 999);
  //     }
  //     const start_Date = startDate == "" ? new Date() : startDate.toISOString();
  //     const end_Date = endDate == "" ? new Date() : endDate.toISOString();
  //     // Check if the start and end dates are less than today
  //     const startDateIsBeforeToday = moment(startDate).isBefore(
  //       moment().startOf("day")
  //     );

  //     setError((prev) => ({
  //       ...prev,
  //       release: sprintID === 0 && Number(selectedReleaseId) === 0,
  //       sprintName: isStringEmpty(sprintName),
  //       startDate:
  //         name === "Start Sprint" &&
  //         (startDateIsBeforeToday || startDate === ""),
  //       endDate:
  //         name === "Start Sprint" &&
  //         (startDateIsBeforeToday || endDate === ""),
  //     }));

  //     // Check if there are any validation errors
  //     if (
  //       isStringEmpty(sprintName) ||
  //       Number(selectedReleaseId) === 0 ||
  //       startDateIsBeforeToday
  //     ) {
  //       ToggleLoader(false)
  //       return; // Do not proceed with the API call or modal close
  //     }

  //     const sprintData = {
  //       projectId,
  //       sprintName,
  //       selectedReleaseId,
  //       start_Date,
  //       end_Date,
  //       sprintID,
  //       userID,
  //     };

  //     const resp = await SprintOverviewService.AddEditSprint(sprintData);

  //     if (resp.status === HTTPResponse.OK) {
  //       ShowToastMessage(isSprintEdit ? Sprint.SprintUpdate : Sprint.SprintCreate, ToastMessageType.Success);
  //       ToggleLoader(false);
  //     }
  //     if (resp.status === HTTPResponse.Unauthorized) {
  //       LogOut();
  //     }
  //   } catch (error) {
  //     ToggleLoader(false);
  //     ShowToastMessage(ToastMessages.Fail, ToastMessageType.Fail);
  //   }
  //   // Clear any previous validation errors and input sections
  //   setValidationError("");
  //   setSprintName("");
  //   setRelease("");
  //   setStartDate("");
  //   setEndDate("");

  //   closeModal();
  // };
  const AddEditSprint = async () => {
    try {
      ToggleLoader(true);

      // Ensure startDate and endDate are valid dates before setting hours
      let currentStartDate = startDate ? new Date(startDate) : new Date();
      let currentEndDate = endDate ? new Date(endDate) : new Date();

      if (currentStartDate) {
        currentStartDate.setHours(23, 59, 59, 999);
      }
      if (currentEndDate) {
        currentEndDate.setHours(23, 59, 59, 999);
      }

      const start_Date = currentStartDate.toISOString();
      const end_Date = currentEndDate.toISOString();

      // Check if the start date is before today (only relevant for adding new sprints)
      const startDateIsBeforeToday =
        !isSprintEdit &&
        moment(currentStartDate).isBefore(moment().startOf("day"));

      // Determine validation errors
      setError((prev) => ({
        ...prev,
        release: sprintID === 0 && Number(selectedReleaseId) === 0,
        sprintName: isStringEmpty(sprintName),
        startDate:
          !isSprintEdit && name === "Start Sprint" && startDateIsBeforeToday,
        endDate:
          name === "Start Sprint" &&
          moment(currentEndDate).isBefore(currentStartDate),
      }));

      // Check if there are any validation errors
      if (
        isStringEmpty(sprintName) ||
        Number(selectedReleaseId) === 0 ||
        (name === "Start Sprint" && !isSprintEdit && startDateIsBeforeToday) ||
        moment(currentEndDate).isBefore(currentStartDate)
      ) {
        ToggleLoader(false);
        return; // Do not proceed with the API call or modal close
      }

      const sprintData = {
        projectId,
        sprintName,
        selectedReleaseId,
        start_Date,
        end_Date,
        sprintID,
        userID,
      };

      const resp = await SprintOverviewService.AddEditSprint(sprintData);

      if (resp.status === HTTPResponse.OK) {
        ShowToastMessage(
          isSprintEdit ? Sprint.SprintUpdate : Sprint.SprintCreate,
          ToastMessageType.Success
        );
        ToggleLoader(false);
      }
      if (resp.status === HTTPResponse.Unauthorized) {
        LogOut();
      }
    } catch (error) {
      ToggleLoader(false);
      ShowToastMessage(ToastMessages.Fail, ToastMessageType.Fail);
    }

    // Clear any previous validation errors and input sections
    // setValidationError("");
    // setSprintName("");
    // setRelease("");
    // setStartDate("");
    // setEndDate("");

    closeModal();
  };

  const GetSprintHistory = async () => {
    try {
      const response = await SprintService.GetSprintHistoryListBySprintId(
        sprintID
      );

      if (response.status === HTTPResponse.OK) {
        setGridData(response.data);
      }

      if (response.status === HTTPResponse.Unauthorized) {
        LogOut();
      }
    } catch (error) {
      ShowToastMessage(ToastMessages.Fail, ToastMessageType.Fail);
    }
  };
  const openContent = (event, tabName) => {
    setActiveTab(tabName);
    if (tabName === "History") {
      GetSprintHistory();
    }
  };
  const defaultColDef = {
    sortable: true,
    flex: 1,
    cellClass: "td",
  };
  const columnDefs = [
    {
      headerName: "Field",
      field: "field",
      flex: 1,
      filter: false,
      sortable: true,
      tooltipField: "field",

      headerClass: "header",
    },
    {
      headerName: "Changed By",
      field: "changedByName",
      flex: 1,
      filter: false,
      sortable: true,
      tooltipField: "changedByName",
      headerClass: "header",
    },
    {
      headerName: "Changed On",
      field: "changedOn",
      flex: 1,
      filter: false,
      sortable: true,
      tooltipField: "changedOn",
      headerClass: "header",
    },
    {
      headerName: "Original Value",
      field: "originalValue",
      flex: 1,
      filter: false,
      sortable: true,
      tooltipField: "originalValue",
      headerClass: "header",
    },
    //     {
    //       headerName: "Task ID",
    //       field: "taskId",
    //       flex: 1,
    //       filter: false,
    //       sortable: true,
    // tooltipField: "taskId",
    // cellRenderer: (params) => (
    //           <div>
    //             <TaskIdLinkRenderer value={params.data.taskId} projectID={params.data.taskId} taskTitle={params.data.taskId}/>
    //           </div>

    //         ),
    //       headerClass: "header",
    //     },
    {
      headerName: "New Value",
      field: "newValue",
      flex: 1,
      filter: false,
      sortable: true,
      tooltipField: "newValue",
      headerClass: "header",
    },
    //     {
    //       headerName: "Issue Sub Type",
    //       field: "key",
    //       flex: 1,
    //       filter: false,
    //       sortable: true,
    // tooltipField: "key",
    //       headerClass: "header",
    //     },
    //     {
    //       headerName: "Task Name",
    //       field: "taskName",
    //       flex: 1,
    //       filter: false,
    //       sortable: true,
    // tooltipField: "taskName",
    //       headerClass: "header",
    //     },
    //     {
    //       headerName: "Comments",
    //       field: "comment",
    //       flex: 1,
    //       filter: false,
    //       sortable: true,
    // tooltipField: "comment",
    //       headerClass: "header",
    //     },
  ];
  const gridOptions = {
    columnDefs: columnDefs,
    defaultColDef,
    rowHeight: DefaultAGGridProps.ROW_HEIGHT,
  };
  const onGridReady = useCallback((params) => {}, []);
  const onSortChanged = (e) => {
    e.api.refreshCells();
  };
  /* <summary>
        date: 07-02-2024
        Name: Mustakim
        description: Format date accordingly.
        <summary>*/
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, "0");
    const monthName = MonthNames[date.getMonth()]; // Assuming MonthNames array exists
    const year = date.getFullYear();
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const seconds = date.getSeconds().toString().padStart(2, "0");
    return `${day}/${monthName}/${year} ${hours}:${minutes}:${seconds} IST`;
  };

  /* <summary>
date: 17-01-2024
Name: Mustakim Shaikh
description: Release Name
<summary>*/
  const formatOnlyDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, "0");
    const monthName = MonthNames[date.getMonth()]; // Get month name from array
    const year = date.getFullYear();
    return `${day}/${monthName}/${year}`;
  };
  return (
    <>
      {isOpen && (
        <div className="create-sprint-modal">
          <div
            className="modal modal-depth"
            id="createSprintModal"
            tabIndex={-1}
            aria-labelledby="ModalFormLabel"
            aria-hidden="true"
            style={{
              display: "block",
              paddingRight: "17px",
              backgroundColor: "#00000094",
            }}
          >
            <div className="modal-dialog modal-dialog-centered modal-custom-width">
              <div className="modal-content" style={{ minHeight: "352px" }}>
                <div className="modal-body">
                  <div className="container">
                    <div className="row modal-headerr">
                      <div className="col-10 col-md-10 col-lg-10">
                        <label className="modal-head-label">
                          {sprintID > MaxCharacterCount.Empty && isSprintEdit
                            ? "Edit " + sprintInputName
                            : name}
                        </label>
                      </div>
                      <div className="col-2 col-md-2 col-lg-2">
                        <button
                          type="button"
                          className="btn-close btn-close-white"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                          onClick={cancelClick}
                        />
                      </div>
                    </div>
                    <div className="create-sprint-main">
                      {isSprintEdit && (
                        <div>
                          <div className="activity-link-tab">
                            <button
                              className={`tablinks comment-tab ${
                                activeTab === "Details" ? "Active" : ""
                              }`}
                              //      style={{ display: actionItemDetails.workLogDetails.length > 0 ? "" : "none" }}
                              onClick={(event) => openContent(event, "Details")}
                              id="defaultOpen"
                            >
                              <span>Details</span>
                            </button>
                            <button
                              className={`tablinks history-tab ${
                                activeTab === "History" ? "Active" : ""
                              }`}
                              onClick={(event) => openContent(event, "History")}
                            >
                              <span>History</span>
                            </button>
                          </div>
                        </div>
                      )}
                      <div
                        id="Details"
                        className={`tabcontent ${
                          activeTab === "Details" ? "active" : ""
                        }`}
                      >
                        <div className="row">
                          {/* Sprint Name */}
                          {sprintID > MaxCharacterCount.Empty &&
                            isSprintEdit && (
                              <span>
                                This sprint contains{" "}
                                <label>{sprintActionItemCount}</label> issues.{" "}
                              </span>
                            )}
                          <div className="col-4 col-md-3 col-lg-3 mt-3 col-pad-none custom-alignment">
                            <label>
                              Sprint Name:{" "}
                              <span className="mandatory-field">*</span>
                            </label>
                          </div>
                          <div className="col-8 col-md-9 col-lg-9 mt-3">
                            <input
                              className="form-control"
                              type="text"
                              id="sprintName"
                              value={sprintName}
                              onChange={handleSprintNameChange}
                            />
                            <ErrorField
                              show={error.sprintName}
                              message={Sprint.SprintName}
                            />
                          </div>
                        </div>
{console.log("sssssssssssssssssssss",storeSprintReleaseData)}
                        {/* {sprintID === MaxCharacterCount.Empty && ( */}
                          <div className="row">
                            <div className="col-4 col-md-3 col-lg-3 mt-3 col-pad-none  custom-alignment" >
                              <label>
                                Release:{" "}
                                <span className="mandatory-field">*</span>
                              </label>
                            </div>
                            <div className="col-8 col-md-9 col-lg-9 mt-3">
                              <select
                                className="form-select"
                                id="release"
                                value={id?id:selectedReleaseId }
                                onChange={(e) =>
                                  handleReleaseSelect(e.target.value)
                                }
                              >
                                {storeSprintReleaseData.map((release) => (
                                  <option
                                    key={release.releaseId}
                                    value={release.releaseId}
                                  >
                                    {release.name}
                                  </option>
                                ))}
                              </select>
                              <ErrorField
                                show={error.release}
                                message={CommonErrorMessages.Required}
                              />
                            </div>
                          </div>
                        {/* // )} */}

                        {/* Start Date */}

                        <div className="row">
                          <div className="col-4 col-md-3 col-lg-3 mt-3 col-pad-none custom-alignment">
                            <label>
                              Start Date:{" "}
                              {name === "Start Sprint" && (
                                <span className="mandatory-field">*</span>
                              )}
                            </label>
                          </div>
                          <div className="col-8 col-md-9 col-lg-9 mt-3">
                            <DatePicker
                              selected={startDate}
                              onChange={handleStartDateChange}
                              dateFormat="dd-MM-yyyy"
                              minDate={today}
                              maxDate={endDate}
                              placeholderText="Select Date"
                              className="form-control"
                              popperClassName="ag-custom-component-popup"
                              scrollableYearDropdown={true}
                              showYearDropdown
                              showMonthDropdown
                              todayButton="Today"
                            />
                            {error.startDate && (
                              <ErrorField
                                show={error.startDate == true}
                                message={CommonErrorMessages.StartDate}
                              />
                            )}
                          </div>
                        </div>

                        {/* End Date */}

                        <div className="row">
                          <div className="col-4 col-md-3 col-lg-3 mt-3 col-pad-none custom-alignment">
                            <label>
                              End Date:{" "}
                              {name === "Start Sprint" && (
                                <span className="mandatory-field">*</span>
                              )}
                            </label>
                          </div>
                          <div className="col-8 col-md-9 col-lg-9 mt-3">
                            <DatePicker
                              selected={endDate}
                              onChange={handleEndDateChange}
                              dateFormat="dd-MM-yyyy"
                              placeholderText="Select Date"
                              minDate={startDate || today}
                              className="form-control"
                              popperClassName="ag-custom-component-popup"
                              scrollableYearDropdown={true}
                              showYearDropdown
                              showMonthDropdown
                              todayButton="Today"
                            />
                            {error.endDate && (
                              <ErrorField
                                show={error.endDate}
                                message={CommonErrorMessages.EndDate}
                              />
                            )}
                          </div>
                        </div>
                        <div>
                          {error.sprintEmpty && (
                            <ErrorField
                              show={error.sprintEmpty}
                              message={Sprint.sprintEmpty}
                            />
                          )}
                        </div>
                        <div className="create-cancel-div">
                          <button
                            className="create-sprint-btn"
                            onClick={() => {
                              if (sprintID && !isSprintEdit) {
                                handleStartSprintClick();
                              } else if (sprintID === 0 || isSprintEdit) {
                                AddEditSprint();
                              }
                            }}
                          >
                            {sprintID && isSprintEdit
                              ? "Save"
                              : sprintID && !isSprintEdit
                              ? "Start"
                              : "Create"}
                          </button>
                          {!isSprintEdit && (
                            <button
                              data-bs-dismiss="modal"
                              aria-label="Close"
                              className="cancel-sprint-btn"
                              onClick={cancelClick}
                            >
                              Cancel
                            </button>
                          )}
                        </div>
                      </div>

                      {isSprintEdit && gridData.length > 0 && (
                        <div
                          id="History"
                          className={`tabcontent ${
                            activeTab === "History" ? "active" : ""
                          }`}
                        >
                          <div className="timetracker-sub-grid-sec sub-table">
                            <div className="custom-grid-sec mt-2 history-main">
                              <table className="table-responsive table-main">
                                <thead>
                                  <tr>
                                    <th className="td-th-alignment">Field</th>
                                    <th className="td-th-alignment">
                                      Changed By
                                    </th>
                                    
                                    <th className="td-th-alignment">
                                      Original Value
                                    </th>
                                    <th className="td-th-alignment">
                                      New Value
                                    </th>
                                    <th className="td-th-alignment">
                                      Changed On
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {gridData.map((history, index) => {
                                    return (
                                      <tr key={index}>
                                        <td className="td-th-alignment">
                                          {history.field}
                                        </td>
                                        <td className="td-th-alignment">
                                          {history.changedByName}
                                        </td>
                                        
                                        <td className="td-th-alignment">
                                          {history.originalValue}
                                        </td>
                                        <td className="td-th-alignment">
                                          {history.newValue}
                                          {/* {history.field !="StartDate" ? formatOnlyDate(history.newValue) : history.newValue} */}
                                        </td>
                                        <td className="td-th-alignment">
                                          {formatDate(history.changedOn)}
                                        </td>
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      )}

                      {isSprintEdit && gridData.length===0 && (
                        <div
                        id="History"
                        className={`tabcontent ${
                          activeTab === "History" ? "active" : ""
                        }`}
                      >
                     <div style={{textAlign:'center'}}>No items to display</div>  
                       </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CreateSprint;
