import React, { useContext, useCallback, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AppContext, useUIContext } from "../Contexts/AppContextProvider";
import { GetLocalStorageData, AddLocalStorageData } from "../Helpers/Utilities";
import UserStatusCell from "./UserStatusCell";
import {
  ROLE_TYPE,
  HTTPResponse,
  ToastMessageType
} from "../Helpers/Enums";
import AssignEmployeeModal from "./AssignEmployeeModal";
import { ProjectService } from "../WebApiServices/Project.service";
import UserCommonCell from "../Components/UserCommonCell";
import AGGrid from "./AGGrid";
import { ToastMessages } from "../Helpers/HelperText";
import DeleteModal from "./DeleteModal";

const ProjectTable = ({ projectData, onChange, onDeleteProject, adminPermission, empPermission, leadPermission }) => {
  const { LogOut } = useUIContext();


  const userId = GetLocalStorageData("userId");
  const roleId = Number(GetLocalStorageData("roleID"));
  const { ToggleLoader, SetProjectId, ShowToastMessage } = useContext(AppContext);
  const navigate = useNavigate();

  const currentItems = projectData;

  const [projectsId, setProjectsId] = useState();
  const [leadName, setLeadName] = useState([]);
  const [addStyle, setStyle] = useState(false);
  const [showDeleteModalPopUp, setShowDeleteModal] = useState(false);
  const [selectedProjectId, setselectedProjectId] = useState(0);
  const [selectedLeadId, setSelectedLeadId] = useState(0);


  // useEffect(() => {
  //   if (selectedLeadId !== undefined) {
  //     getAllLeadName();
  //   }
  // }, [selectedLeadId]);


  const getAllLeadName = async (leadId) => {
    try {
      const response = await ProjectService.GetLeadNames();
      if (response.status === HTTPResponse.OK) {

        const leadData = response.data.value;
        const selectedLeadOption = leadData.find(
          (lead) => lead.id === leadId
        );
        const leadSelected = selectedLeadOption !== undefined ? selectedLeadOption.id : 0;
        const updatedLeadData = leadData.map(lead => {
          if (leadSelected !== 0 && lead.id === leadSelected) {
            return { ...lead, isChecked: true };
          } else {
            return { ...lead, isChecked: false }; // Uncheck other leads
          }
        });
        setLeadName(updatedLeadData);

      } else {
        ShowToastMessage(ToastMessages.Fail, ToastMessageType.Fail);
      }
    } catch (e) {
      ShowToastMessage(ToastMessages.Fail, ToastMessageType.Fail);
    }
  }


  const [openModal, setOpenModal] = useState(false);

  const handleCloseEmployeeAssignModal = () => {
    setOpenModal(false);
  };

  const [updatedEmployeeData, setUpdatedEmployeeData] = useState([]);

  const handleEmployeeUpdate = (updatedData) => {
    setUpdatedEmployeeData(updatedData);
  };

  const columnDefs = (adminPermission, empPermission, leadPermission) => {
    return [
      {
        headerName: "Key",
        field: "key",
        flex: 1,
        filter: false,
        sortable: true,
        cellRenderer: (params) => (
          <div onClick={() => handleNameClick(params.data)}>
            <UserCommonCell data={params.data} fieldName="key" />
          </div>
        ),
        headerClass: "header",
      },
      {
        headerName: "Project",
        field: "name",
        flex: 1,
        filter: false,
        sortable: true,
        cellRenderer: (params) => (
          <div onClick={() => handleNameClick(params.data)}>
            <UserCommonCell data={params.data} fieldName="name" />
          </div>
        ),
        headerClass: "header",
      },

      {
        headerName: "Client Name",
        field: "clientName",
        flex: 1,
        filter: false,
        sortable: true,
        hide: roleId != ROLE_TYPE.ADMIN,
        cellRenderer: (params) => (
          <div onClick={() => handleNameClick(params.data)}>
            <UserCommonCell data={params.data} fieldName="clientName" />
          </div>
        ),

        headerClass: "header",
      },

      // Team Name
      {
        headerName: "Project Lead",
        field: "projectLead",
        flex: 1,
        filter: false,
        sortable: true,
        cellRenderer: (params) => (
          <div onClick={() => handleNameClick(params.data)}>
            <UserCommonCell data={params.data} fieldName="projectLead" />
          </div>
        ),
        headerClass: "header",
      },

      // User Status

      {
        headerName: "",
        field: "status",
        width: 114,
        filter: false,
        sortable: false,

        unSortIcon: false,
        hide: roleId != ROLE_TYPE.ADMIN,
        cellRenderer: (params) => (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <UserStatusCell
              active={params.data.status}
              id={params.data.id}
              onStatusChange={() => null}
              context="projects"
              onChanges={onChange}
            />
          </div>
        ),
        headerClass: "header",
        colId: "status",
      },
      {
        headerName: "",
        width: 38,
        filter: false,
        sortable: false,
        unSortIcon: false,
        hide: roleId != ROLE_TYPE.ADMIN,
        cellRenderer: (params) => (
          <div
            onClick={() => handleNameClick(params.data)}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              verticalAlign: "center",
              height: "100%",
            }}
          >
            <button
              className="edit-button"
              onClick={(e) => handleProjectTeam(e, params.data)}
            >
              <i
                className="fa fa-users"
                aria-hidden="true"
                style={{ color: "#380657", fontSize: 25 }}
                title="Assigned Employees"
                onClick={(e) => handleProjectTeam(e, params.data)}
              />
            </button>
          </div>
        ),
        headerClass: "header",
        colId: "Assign",
      },
      {
        headerName: "",
        width: 38,
        filter: false,
        sortable: false,
        unSortIcon: false,
        hide: (roleId != ROLE_TYPE.ADMIN && roleId != ROLE_TYPE.LEAD) || roleId !== ROLE_TYPE.ADMIN && adminPermission.ManageProject.editOther === 0 ||
          roleId === ROLE_TYPE.EMPLOYEE && empPermission.ManageProject.edit === 0
          || roleId === ROLE_TYPE.LEAD && leadPermission.ManageProject.edit === 0,
        //hide: (roleId != ROLE_TYPE.ADMIN && roleId != ROLE_TYPE.LEAD),
        cellRenderer: (params) => (
          <div
            onClick={() => handleNameClick(params.data)}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              verticalAlign: "center",
              height: "100%",
            }}
          >
            <button
              className="edit-button"
              onClick={(e) => handleEditProject(e, params.data)}
            >
              <i
                className="fa fa-2x fa-pencil-square-o"
                aria-hidden="true"
                style={{ color: "#380657" }}
                title="Edit"
              />
            </button>
          </div>
        ),
        headerClass: "header",
      },
      {
        headerName: "",
        field: "DeleteButton",
        width: 50,
        border: 0,

        filter: false,
        sortable: false,
        pinned: "right",
        unSortIcon: false,
        hide: (roleId != ROLE_TYPE.ADMIN && adminPermission.ManageProject.deleteOther === 0) ||
          roleId === ROLE_TYPE.EMPLOYEE && empPermission.ManageProject.delete === 0
          || roleId === ROLE_TYPE.LEAD && leadPermission.ManageProject.delete === 0,
        cellRenderer: (params) => (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              verticalAlign: "center",
              height: "100%",
            }}
          >
            <button
              className="edit-button"
              onClick={(e) => handleDelete(e, params.data)}

            >
              <i
                className="fa fa-2x fa-trash"
                aria-hidden="true"
                style={{ color: "#e6072c" }}
                title="Delete"
              />
            </button>
          </div>
        ),
        headerClass: "header",
      },
    ];
  };

  const AddLAstVisitedProject = async (employeeId, projectId) => {

    const response = await ProjectService.AddLastVisitedProject(employeeId, projectId);
    try {
      // ToggleLoader(true);

      if (response.status != HTTPResponse.OK) {
        ShowToastMessage(ToastMessages.Fail, ToastMessageType.Fail);
      }
    }
    catch (error) {
      ToggleLoader(false);
      ShowToastMessage(ToastMessages.Fail, ToastMessageType.Fail);
    }
  }
  const handleNameClick = async (item) => {

    const projectid = item.id;
    const projectName = item.name;
    SetProjectId(projectid, projectName);
    // const existing = GetLocalStorageData("lastVisitedProjectIds");
    // let updatedProjects = [];    
    // if (existing) {
    //   updatedProjects = JSON.parse(existing).filter((id) => id !== projectid);
    // }
    // updatedProjects.unshift(projectid);
    // //const limitedProjects = updatedProjects.slice(0, 5);
    // AddLocalStorageData("lastVisitedProjectIds", JSON.stringify(updatedProjects));


    await AddLAstVisitedProject(userId, item.id);

    AddLocalStorageData("projectName", projectName);
    AddLocalStorageData("key", projectid);
    navigate(`/Overview/${projectid}`);
  };

  const handleEditProject = async (event, item) => {
    event.stopPropagation();
    let response = await ProjectService.GetAllProjects();

    const projectNames = response.data && response.data.length > 0 && response.data.map((project) => ({
      id: project.id,
      name: project.name,
    }));

    const projectKey = response.data && response.data.length > 0 && response.data.map((project) => ({
      id: project.id,
      name: project.key,
    }));

    const projectName = item.name;
    AddLocalStorageData("projectName", projectName);

    const projectid = item.id;
    SetProjectId(projectid, item.name);

    // const existing = GetLocalStorageData("lastVisitedProjectIds");
    // let updatedProjects = [];    
    // if (existing) {
    //   updatedProjects = JSON.parse(existing).filter((id) => id !== projectid);
    // }
    // updatedProjects.unshift(projectid);
    // //const limitedProjects = updatedProjects.slice(0, 5);
    // AddLocalStorageData("lastVisitedProjectIds", JSON.stringify(updatedProjects));
    await AddLAstVisitedProject(userId, item.id);
    navigate("/EditProject", {
      state: {
        projectDetails: item,
        existingProjects: projectNames,
        Key: projectKey,
      },
    });
  };

  const handleProjectTeam = (event, item) => {
    const projectid = item.id;
    SetProjectId(projectid, item.name);
    setOpenModal(true);
    setProjectsId(projectid);
    setSelectedLeadId(item.leadID);
    event.stopPropagation();
    getAllLeadName(item.leadID);
  };

  //AG Grid Area
  const onSortChanged = (e) => {
    e.api.refreshCells();
  };

  const onGridReady = useCallback((params) => {
  }, []);




  //SYJ - Delete Project from Grid But not from DB
  const handleDelete = async (event, item) => {
    event.stopPropagation();
    setselectedProjectId(item.id);
    setShowDeleteModal(true);
  };

  const OnDeleteModalYesClick = async () => {
    try {
      let idToDelete = selectedProjectId;
      if (idToDelete > 0) {
        var deleteRes = await ProjectService.DeleteProject(idToDelete);
        if (deleteRes.data !== undefined && deleteRes.data === true) {
          // isProjectDeleted = true;
          ShowToastMessage(ToastMessages.ProjectDelete, ToastMessageType.Success);
        }
        if (deleteRes.data === null && deleteRes.data === undefined && deleteRes.status === HTTPResponse.Unauthorized) {
          // isProjectDeleted = false;
        }
      }
      setShowDeleteModal(false);
      onDeleteProject();
    }
    catch (error) {
      ShowToastMessage(ToastMessages.Fail, ToastMessageType.Fail);
    }
  };

  const OnCloseDeleteModal = () => {
    setShowDeleteModal(false);
  };


  return (
    <>
      {openModal && (
        <AssignEmployeeModal
          editMode={true}
          IsStyle={addStyle}
          closeModal={handleCloseEmployeeAssignModal}
          projectIds={projectsId}
          allEmployees={leadName}
          onEmployeeUpdate={handleEmployeeUpdate}
        />
      )}


      {showDeleteModalPopUp && (
        <DeleteModal
          showDeleteModal={showDeleteModalPopUp}
          onYesClick={OnDeleteModalYesClick}
          onNoClick={OnCloseDeleteModal}
          textToDisplay={"Are you sure you want to delete?"}
        />
      )}

      {(roleId === ROLE_TYPE.ADMIN || roleId !== ROLE_TYPE.ADMIN && adminPermission.ManageProject.viewOther === 1 || roleId === ROLE_TYPE.EMPLOYEE
        && empPermission.ManageProject.view === 1 || roleId === ROLE_TYPE.LEAD && leadPermission.ManageProject.view === 1) &&
        <AGGrid
          rowData={currentItems}
          colDefs={columnDefs(adminPermission, empPermission, leadPermission)}
          pagination="10"
          isProject={true}
          onGridReady={onGridReady}
          onSortChanged={(e) => onSortChanged(e)}
          showOverlay={true}
        />}
    </>
  );
};

export default ProjectTable;
