import React, { useState, useEffect, useContext } from "react";
import Select, { components } from "react-select";
import { EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { FaTimes } from "react-icons/fa"; // Import cross icon from react-icons library

import {
  HTTPResponse,
  ToastMessageType,
  MaxCharacterCount,
  STATUS_TYPE,
  toolbarOptions,
  ROLE_TYPE,
} from "../Helpers/Enums";
import {
  CommonErrorMessages,
  ToastMessages,
  ReportErrorMessage,
} from "../Helpers/HelperText";
import { ReportService } from "../WebApiServices/Report.service";
import { ProjectService } from "../WebApiServices/Project.service";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ErrorField from "../Components/ErrorField";
import { AppContext, useUIContext } from "../Contexts/AppContextProvider";
import {
  ConvertFromHTML,
  ConvertToHtml,
  sanitizeHTML,
  isStringEmpty,
  GetLoggedInUserID,
  changePageTitle,
  GetLocalStorageData,
  stripHTMLTags
} from "../Helpers/Utilities";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import BreadcrumbComponent from "../Components/BreadcrumbComponent";
import AddMiscellenousOther from "../Components/AddMiscellenousOther";
import DeleteModal from "../Components/DeleteModal";

// const stripHTMLTags = (html) => {
//   return html != undefined ? html.replace(/<[^>]*>?/gm, '') : "";
// };

const AddEditReport = () => {
  /* <summary>
  date: 07-11-2023
  Name: MS
  description: editMode is used to not clear input fields on click of save button.
  <summary>*/

  const NUMBER_REGEX = /^\d*(\.\d{0,5})?$/s;
  const { LogOut } = useUIContext();

  const [startDate, setStartDate] = useState(new Date());
  const navigate = useNavigate();
  const pageParams = useParams();
  const projectId = pageParams.projectId;
  const { state } = useLocation();
  const location = useLocation();

  const [disableSave, setDisableSave] = useState(true);
  const [isMisOtherModalOpen, setIsMisOtherModalOpen] = useState(false);
  const [isSaved, setIsSave] = useState(false);
  const editMode = state ? state.editMode : false;
  const {  selectedFilters,dateSelected } = location.state || {};

  const [existingTimeLogDetails, setExistingTimeLogDetails] = useState("");
  const [wrkLogId, setWorkLogId] = useState("");
  const [isTrainingMiscOther, setIsTrainingMiscOther] = useState(false);
  const [isMiscOther, setIsMiscOther] = useState(false);
  const [miscSelectedlabelToOthersModal, setMiscSelectedlabelToOthersModal] = useState("");
  const [showDeleteModalPopUp, setShowDeleteModal] = useState(false);
  const [miscIdToDelete, setMiscIdToDelete] = useState(false);


  const [isEditLoadData, setIsEditLoadData] = useState(false);
  const roleId = Number(GetLocalStorageData("userRole"));

  const [employeeId, setEmployeeId] = useState(GetLoggedInUserID());

  let reportDate = state ? state.reportDate : null;
  const reportDatett = state ? state.reportDatett : false;
  useEffect(() => {
    if (reportDatett) {
      reportDate = reportDatett;
    }
  }, [reportDatett]);

  const { ShowToastMessage, ToggleLoader } = useContext(AppContext);
  const [addEditReportData, setAddEditReportData] = useState([
    {
      date: new Date(),
      selectedProject: "",
      filteredprojectList: [],
      reportId: 0,
      projectList: [],
      projectId: "",
      timeSpent: "",
      error: {
        selectedProject: false,
        time: false,
        timeValid: false,
        description: false,
      },
      description: EditorState.createEmpty(),
      showErrorOnSave: false,
    },
  ]);
  const [projectList, setProjectList] = useState([]);
  const { appState } = useContext(AppContext);

  /* <summary>
  date: 19-10-2023
  Name: NGG
  description: binds data to add edit report page
  <summary>*/
  useEffect(
    () => {
      async function fetchData() {
        try {
          await getReportData();
        } catch (error) {
          ShowToastMessage(ToastMessages.Fail, ToastMessageType.Fail);
        }
      }
      fetchData();
      ToggleLoader(false);
    },
    [],
    [reportDatett]
  );

  useEffect(() => {
    setIsEditLoadData(true);
    const existingTimeLogDetailsState = state && state.timeLogDetails;
    changePageTitle(existingTimeLogDetailsState !== null && existingTimeLogDetailsState?.workLogDetailID > 0 ? "Edit Time Log" : "Add Time Log");
    if (Array.isArray(existingTimeLogDetailsState) || existingTimeLogDetailsState != null) {
      let projectDetails = {
        label: existingTimeLogDetailsState.project,
        value: existingTimeLogDetailsState.projectID
      }
      const hrsMin = convertHoursToHHMM(existingTimeLogDetailsState.hours);
      const [hrs, min] = hrsMin.split(":");
      setWorkLogId(existingTimeLogDetailsState?.workLogDetailID > 0 ? existingTimeLogDetailsState?.workLogDetailID : 0);
      setAddEditReportData((prev) => ({
        ...prev,
        date: existingTimeLogDetailsState.date,
        selectedProject: projectDetails,
        reportId: existingTimeLogDetailsState?.workLogDetailID,
        projectId: existingTimeLogDetailsState.projectID,
        timeSpent: { hours: hrs, mins: min },
      }));

      setSelectedProject((prev) => ({
        ...prev,
        label: existingTimeLogDetailsState.project,
        value: existingTimeLogDetailsState.projectID
      }));

      setMiscValue((prev) => ({
        ...prev,
        value: existingTimeLogDetailsState.miscellaneousTimeLogId > 0 ? existingTimeLogDetailsState.miscellaneousTimeLogId : 0,
        label: existingTimeLogDetailsState.miscellaneousTimeLogId > 0 ? existingTimeLogDetailsState.miscName : ""
      }));

      setSelectedTask((prev) => ({
        ...prev,
        value: existingTimeLogDetailsState.workLogTaskID > 0 ? existingTimeLogDetailsState.workLogTaskID : 0,
        label: existingTimeLogDetailsState.taskId,
      }));


      setFieldValues((prev) => ({
        ...prev,
        title: existingTimeLogDetailsState.taskName,
        originaleEst: existingTimeLogDetailsState.originalEstimate,
        timeSpent: { hours: hrs, mins: min },
        comments: existingTimeLogDetailsState.comment != "" ? stripHTMLTags(existingTimeLogDetailsState.comment) : "",
        workLogDetailId: existingTimeLogDetailsState?.workLogDetailID
      }));

      setData((prev) => ({
        ...prev,
        date: new Date(existingTimeLogDetailsState.loggedDate),
        timeSpent: { hours: hrs, mins: min },
        comments: existingTimeLogDetailsState.comment != "" ? stripHTMLTags(existingTimeLogDetailsState.comment) : "",
        taskName: existingTimeLogDetailsState.taskName,
      }));
    }
  }, []);

  // const handleTimeChange = (e) => {
  //     const { id, value } = e.target;
  //     let hours = addEditWorkLogDetails.timeSpent.hours;
  //     let minutes = addEditWorkLogDetails.timeSpent.minutes;

  //     if (id === 'hrs') {
  //       hours = value;
  //     } else if (id === 'min') {
  //       minutes = value;
  //     }

  //     setAddEditWorkLogDetails((prev) => ({
  //       ...prev,
  //       timeSpent: {
  //         hours: hours
  //         //.padStart(2, '0')
  //         , // Ensure two-digit format
  //         minutes: minutes
  //         // .padStart(2, '0')
  //         , // Ensure two-digit format
  //       },
  //     }));
  //   };

  useEffect(() => {
    const projectListdata = [...addEditReportData];
    projectListdata.forEach((x) => (x.projectList = projectList));
    setAddEditReportData(projectListdata);
    ToggleLoader(false);
  }, [projectList]);

  /* <summary>
  date: 19-10-2023
  Name: NGG
  description: validates input field of  the repeater
  <summary>*/
  const ValidateField = () => {
    let reportData = [...addEditReportData];
    let error = false;

    reportData.forEach((x) => {
      if (!x.selectedProject) {
        error = true;
        x.error.selectedProject = true;
      }
      if (isStringEmpty(x.timeSpent.toString())) {
        error = true;
        x.error.time = true;
        x.error.timeValid = false;
      } else {
        if (parseFloat(x.timeSpent) <= 0) {
          error = true;
          // Check if hrs is less than 0.1
          x.error.timeValid = true;
          x.error.time = false;
        }
      }

      if (x.description.getCurrentContent().getPlainText().length == 0) {
        error = true;
        x.error.description = true;
      }

      return x;
    });

    setAddEditReportData(reportData);

    if (error) return false;
    else return true;
  };

  /* <summary>
  date: 19-10-2023
  Name: NGG
  description: gets project list
  <summary>*/
  const getProjectList = async () => {
    const response = await ProjectService.GetAllProjects();

    const data = response?.data
      ?.filter((t) => t.status == STATUS_TYPE.ACTIVE)
      .map((t) => ({
        value: t.id,
        label: t.name,
      }));

    const sortedData = data.sort((a, b) => a.label.localeCompare(b.label));

    setProjectList(sortedData);
  };

  /* <summary>
  date: 19-10-2023
  Name: NGG
  description: gets add edit report data 
  <summary>*/
  const getReportData = async () => {
    try {
      let projectListdata = [];
      await getProjectList();
      if (reportDate) {
        const employeeId = GetLoggedInUserID();
        let response = await ReportService.GetaddEditReportsDetails(
          employeeId,
          reportDate
        );
        if (response.status === HTTPResponse.OK) {
          projectListdata = response?.data?.map((t) => ({
            date: t.reportDate,
            error: {},
            reportId: t.dailyReportId,
            projectId: t.projectId,
            selectedProject: t.projectList.filter(
              (x) => x.value == t.projectId
            ),
            projectList: t.projectList,
            timeSpent: t.timeSpent,
            description: ConvertFromHTML(sanitizeHTML(t.details)),
          }));

          setStartDate(new Date(projectListdata[0].date));
          setAddEditReportData(projectListdata);
          ToggleLoader(false);
        }
        if (response.status === HTTPResponse.Unauthorized) {
          LogOut();
        }
      }
    } catch (error) {
      ShowToastMessage(ToastMessages.IssueOccured, ToastMessageType.Fail);
    }
  };

  /* <summary>
  date: 19-10-2023
  Name: NGG
  description: sets input text field values
  <summary>*/
  const HandleFieldChange = (e, id) => {
    const { name, value } = e.target;
    const hrs = parseFloat(value);

    let reportData = [...addEditReportData];
    if (NUMBER_REGEX.test(value)) {
      if (hrs <= 0) {
        // Check if hrs is less than 0.1
        reportData[id][name] = value;
        reportData[id].error["timeValid"] = true;
        reportData[id].error["time"] = false;
      } else {
        reportData[id][name] = value;
        reportData[id].error["timeValid"] = false;
        reportData[id].error["time"] = false;
      }
      setAddEditReportData(reportData);
    }
  };

  /* <summary>
  date: 19-10-2023
  Name: NGG
  description: sets dropdown value
  <summary>*/
  const HandleDropdownChange = (e, index, name) => {
    const value = Number(e.value);
    let reportData = [...addEditReportData];

    reportData[index][name] = e;
    reportData[index]["projectId"] = value;
    reportData[index]["error"]["selectedProject"] = false;

    setAddEditReportData(reportData);
  };

  /* <summary>
  date: 19-10-2023
  Name: NGG
  description: saves add edit report data
  <summary>*/
  const SaveReport = async () => {
    try {
      setDisableSave(true);
      // const delay = 1500; // 1.5 seconds
      if (ValidateField()) {
        const formData = new FormData();
        let data = addEditReportData.map((x) => ({
          DailyReportId: x.reportId,
          ReportDate: startDate,
          EmployeeId: GetLoggedInUserID(),
          ProjectId: x.projectId,
          TimeSpent: x.timeSpent,
          Details: sanitizeHTML(ConvertToHtml(x.description)),
        }));
        formData.append("data", JSON.stringify(data));
        let projectListdata = [];

        let response = await ReportService.SaveDailyReports(formData);

        if (response.status === HTTPResponse.OK) {
          if (response.data.length === 0) {
            ShowToastMessage(ToastMessages.DateExist, ToastMessageType.Fail);
          } else {
            // setDisableSave(true)
            ShowToastMessage(
              ToastMessages.SaveReport,
              ToastMessageType.Success
            );
          }
          if (response.data.length !== 0) {
            ToggleLoader(true);
            projectListdata = response?.data?.map((t) => ({
              date: t.reportDate,
              reportId: t.dailyReportId,
              projectId: t.projectId,
              selectedProject:
                t.projectList != null &&
                t.projectList.filter((x) => x.value == t.projectId),
              projectList: t.projectList,
              timeSpent: t.timeSpent,
              error: {},
              description: ConvertFromHTML(sanitizeHTML(t.details)),
            }));

            await getProjectList();
            const string = startDate.toISOString();
            const dateString = string.replace("Z", "");
            navigate("/EditTimeLogging", {
              state: { reportDatett: dateString, editMode: true },
            });
          }
        }
        if (response.status === HTTPResponse.Unauthorized) {
          LogOut();
        }
      }
    } catch (error) {
      ToggleLoader(false);
      ShowToastMessage(ToastMessages.IssueOccured, ToastMessageType.Fail);
    }
  };

  /* <summary>
  date: 22/11/2023
  Name: SYJ
  description: sets editor state
  Fixed issue on click of content area as error msg was displayed
  <summary>*/
  const HandleEditorChange = (e, id) => {
    let reportData = [...addEditReportData];

    const currentContent = reportData[id].description.getCurrentContent();
    const newContent = e.getCurrentContent();

    reportData[id]["description"] = e;
    if (currentContent !== newContent) {
      const editorContent = e.getCurrentContent();
      const hasText = editorContent.hasText();

      reportData[id].error["description"] = hasText ? false : true; // Set error to true if no text
      setAddEditReportData(reportData);
    }

    setAddEditReportData(reportData);
  };

  /* <summary>
  date: 19-10-2023
  Name: NGG
  description: add new project to the lost of existing projects in the add edit report page
  <summary>*/
  const AddProject = () => {
    setDisableSave(false);
    const updateList = [
      ...addEditReportData,
      {
        date: new Date(),
        selectedProject: "",
        reportId: 0,
        projectList: addEditReportData[0].projectList,
        projectId: "",
        timeSpent: "",
        error: {},
        description: EditorState.createEmpty(),
      },
    ];

    setAddEditReportData(updateList);
  };
  /* <summary>
  date: 04-12-2023
  Name: MS
  description: To show  delete button only if adding new report and all the fields are empty.
  <summary>*/
  const isDeleteButtonVisible = (item) => {
    return (
      !item.selectedProject &&
      !item.timeSpent &&
      !item.description.getCurrentContent().hasText()
    );
  };

  const DeleteProject = (index) => {
    const updatedList = [...addEditReportData];
    updatedList.splice(index, 1);
    setAddEditReportData(updatedList);
    setDisableSave(true);
  };
  const handleDateChange = (date) => {
    date.setHours(23, 59, 59, 999);
    setStartDate(date);
  };
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  const [projects, setProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState({
    value: 0,
    label: "",
  });
  const [selectedTask, setSelectedTask] = useState({
    value: 0,
    label: "",
  });
  const [fieldValues, setFieldValues] = useState({
    title: "",
    originaleEst: 0,
    timeSpent: { hours: "00", mins: "00" }, comments: "", workLogDetailId: 0
  });
  const [data, setData] = useState({
    date:dateSelected?dateSelected: new Date(),
    timeSpent: { hours: "00", mins: "00" },
    comments: "",
    taskName: "",
    originalEstimate: ""
  });
  const [TaskIds, setTaskIds] = useState([]);
  const [errors, setErrors] = useState({
    TimeSpent: false,
    Project: false,
    Task: false,
    TaskName: false,
    Comments: false,
    MiscDrp: false,
  });
  const [misc, setMisc] = useState([]);
  const [miscValue, setMiscValue] = useState({ value: 0, label: "" });

  // List of non-deletable options
  const nonDeletableOptions = [
    "React", "Dot Net", "Python", "Database", "Internet",
    "Games", "Full Day", "Drill", "Assessment", "Audit",
    "Meeting", "Half Day", "Birthday Celebration", "System Issues", "Other"
  ];

  const handleMiscChange = (selectedOption) => {
    if (selectedOption === null) {
      setMiscValue({
        value: 0,
        label: "",
      });
    }
    else {

      // setData((prev) => ({
      //   ...prev,
      //   timeSpent: { hours: "00", mins: "00" },
      // }));

      // if (selectedOption.value === 5) {
      //   setIsMisOtherModalOpen(true);
      //   setIsTrainingMiscOther(true);
      //   setIsMiscOther(false);
      //   setMiscSelectedlabelToOthersModal("Training");

      // }
      if (selectedOption.value === 17 && (roleId === ROLE_TYPE.ADMIN || roleId === ROLE_TYPE.LEAD)) {
        setData((prev) => ({
          ...prev,
          timeSpent: { hours: "00", mins: "00" },
        }));
        setIsMisOtherModalOpen(true);
        setIsTrainingMiscOther(false);
        setIsMiscOther(true);
        setMiscSelectedlabelToOthersModal("Other");
      }
      else if (selectedOption.value === 11) {
        setData((prev) => ({
          ...prev,
          timeSpent: { hours: "08", mins: "00" },
        }));
        setMiscValue(selectedOption);
        setErrors((prev) => ({
          ...prev,
          MiscDrp: false,
          Project: false,
        }));
      }
      else if (selectedOption.value === 12) {
        setData((prev) => ({
          ...prev,
          timeSpent: { hours: "04", mins: "00" },
        }));
        setMiscValue(selectedOption);
        setErrors((prev) => ({
          ...prev,
          MiscDrp: false,
          Project: false,
        }));
      }
      else if ((miscValue && miscValue.value === 11)||(miscValue && miscValue.value === 12)) {
        setData((prev) => ({
          ...prev,
          timeSpent: { hours: "00", mins: "00" },
        }));
        setMiscValue(selectedOption);
        setErrors((prev) => ({
          ...prev,
          MiscDrp: false,
          Project: false,
        }));
      }
      else {
        // setData((prev) => ({
        //   ...prev,
        //   timeSpent: { hours: "00", mins: "00" },
        // }));
        setMiscValue(selectedOption);
        setErrors((prev) => ({
          ...prev,
          MiscDrp: false,
          Project: false,
        }));
      }

    }
  };


  //SYJ - 17/07/2024 - Changes For Including delete Functionality for DropDown.
  const CustomOption = (props) => {

    const { data, innerRef, innerProps } = props;
    const handleDeleteClick = (e) => {
      e.stopPropagation(); // Prevent the select dropdown from closing
      props.onDeleteOption(data);
    };

    return (
      <div ref={innerRef} {...innerProps} className="custom-option" style={{ display: "flex", flex: 1 }}>
        <components.Option {...props} />
        {(roleId === ROLE_TYPE.ADMIN || roleId === ROLE_TYPE.LEAD) && !nonDeletableOptions.includes(data.label)&&
        data.value !==17 && (
          <span
            className="delete-icon"
            onClick={handleDeleteClick}
            style={{ alignContent: "center" }}
          ><i
              className="fa fa-trash"
              aria-hidden="true"
              style={{ color: "#e6072c", fontSize: "20px", marginRight: "15px", marginLeft: "5px" }}
              title="Delete"
            />
          </span>
        )}
      </div>
    );
  };

  const handleDeleteOption = (optionToDelete) => {
    setMiscIdToDelete(optionToDelete.value);
    setShowDeleteModal(true);
  };

  const OnDeleteModalYesClick = async () => {
    try {
      let idToDelete = miscIdToDelete;
      if (idToDelete > 0) {
        var deleteRes = await ReportService.DeleteMiscOthers(idToDelete);
       
        if (deleteRes.data === "IsDeleted") {
          ShowToastMessage(ToastMessages.MiscOtherDelete, ToastMessageType.Success);
        }
        // const updatedMisc = misc.map((category) => ({
        //   ...category,
        //   options: category.options.filter((option) => option.value !== optionToDelete.value),
        // }));
        // setMisc(updatedMisc);
      }
      GetMiscellaneousTimeLog();
      setShowDeleteModal(false);
    }
    catch (error) {
      ShowToastMessage(ToastMessages.Fail, ToastMessageType.Fail);
    }
  };

  const OnCloseDeleteModal = () => {
    setShowDeleteModal(false);
  };

  const Validation = () => {
    let result = false;
    // let isTimeSpentEmpty = data.timeSpent.hours === "00" && data.timeSpent.mins === "00" ||data.timeSpent.hours === "0" && data.timeSpent.mins === "0"||data.timeSpent.hours === "00" && data.timeSpent.mins === "0"||data.timeSpent.hours === "0" && data.timeSpent.mins === "00"|| data.timeSpent.hours === "" || data.timeSpent < 0 || data.timeSpent === null || data.timeSpent === undefined;
    let isTimeSpentEmpty = (
      data.timeSpent.hours === "00" &&
      (data.timeSpent.mins === "00" || data.timeSpent.mins === undefined)
    );
    let isProjectEmpty = selectedProject.value === 0;
    let isTaskEmpty = miscValue.value === 0 && selectedTask.value === 0;
    let isCommentsEmpty = isStringEmpty(data.comments) && isStringEmpty(fieldValues.comments);
    let isMiscEmpty = miscValue.value === 0;
    let isTaskNameEmpty = ((isStringEmpty(data.taskName) || isStringEmpty(fieldValues.title)))

    setErrors((prev) => ({
      ...prev,
      TimeSpent: isTimeSpentEmpty,
      Task: isTaskEmpty,
      Comments: isCommentsEmpty,
      TaskName: isTaskNameEmpty,
    }));
    if (isProjectEmpty && isMiscEmpty) {
      setErrors((prev) => ({
        ...prev,
        Project: true,
        MiscDrp: true,
      }));
    }
    if (!isProjectEmpty && isMiscEmpty) {
      isMiscEmpty = false;
      setErrors((prev) => ({
        ...prev,
        MiscDrp: false,
        //   TimeSpent: false, Comments: false
      }));
    }
    if (!isMiscEmpty && isProjectEmpty) {
      isTaskEmpty = false;
      isProjectEmpty = false;
      setErrors((prev) => ({
        ...prev,
        Project: false, TaskName: isTaskNameEmpty
      }));
    }
    const c1 = isProjectEmpty && isMiscEmpty;
    const c2 = !isProjectEmpty && isMiscEmpty;
    const c3 = !isMiscEmpty && isProjectEmpty;

    if ((selectedTask.value === 0 ? isTimeSpentEmpty : false) || c1 || isTaskEmpty || isCommentsEmpty || isTaskNameEmpty || c2 || c3) {
      result = true;
    }

    return result;
  };

  useEffect(() => {
    //if (isEditLoadData) {
    getGetTaskDetails();
    // }
  }, [selectedTask]);

  // useEffect(() => {
  //   if (isEditLoadData && selectedTask.value > 0 && miscValue.value == 0) {
  //     GetTaskDetailsByWorkLogId();
  //   }
  // }, [isEditLoadData]);

  const convertHoursToHHMM = (decimalHours) => {
    const hours = Math.floor(decimalHours);
    const minutes = Math.round((decimalHours - hours) * 100) || 0;
    return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
    //return `${hours}:${minutes}`

  };

  const getGetTaskDetails = async () => {
    try {
      if (selectedTask.value > 0) {
        const response = await ReportService.GetTaskDetailsForAdd(
          selectedTask.value,
          GetLoggedInUserID()
        )
        // : await ReportService.GetTaskDetails(
        //   selectedTask.value,
        //   wrkLogId,
        //   GetLoggedInUserID()
        // );
        if (response.data) {
          const hrsMin = convertHoursToHHMM(response.data.timeSpent);
          const [hrs, min] = hrsMin.split(":");
          setFieldValues((prev) => ({
            ...prev,
            title: response.data.title,
            originaleEst: response.data.originalEstimate,
            timeSpent: { hours: hrs, mins: min },
            comments: response.data.comments != "" ? stripHTMLTags(response.data.comments) : "",
            workLogDetailId: response.data.workLogDetailId
          }));
          setErrors((prev) => ({
            ...prev, TaskName: false, TimeSpent: false, Comments: false
          }))
          setData((prev) => ({
            ...prev,
            timeSpent: { hours: hrs, mins: min },
            comments: response.data.comments != "" ? stripHTMLTags(response.data.comments) : "",
            taskName: response.data.title,
          }))
          // setSelectedTask((prev)=>({
          //   ...prev,label:response.data.title
          // }))
        }
      }
    } catch (error) {
      ToggleLoader(false);
      ShowToastMessage(ToastMessages.IssueOccured, ToastMessageType.Fail);
    }
  };

  const GetTaskDetailsByWorkLogId = async () => {
    try {
      if (selectedTask.value > 0) {

        const response = await ReportService.GetTaskDetailsByWorkLogId(
          selectedTask.value,
          wrkLogId,
          GetLoggedInUserID()
        );
        if (response.data) {
          const hrsMin = convertHoursToHHMM(response.data.timeSpent);
          const [hrs, min] = hrsMin.split(":");
          setFieldValues((prev) => ({
            ...prev,
            title: response.data.title,
            originaleEst: response.data.originalEstimate,
            timeSpent: { hours: hrs, minutes: min },
            comments: response.data.comments != "" ? stripHTMLTags(response.data.comments) : "",
            workLogDetailId: response.data.workLogDetailId
          }));

          setData((prev) => ({
            ...prev,
            timeSpent: { hours: hrs, minutes: min },
            comments: response.data.comments != "" ? stripHTMLTags(response.data.comments) : "",
            taskName: response.data.title,
          }))
          // setSelectedTask((prev)=>({
          //   ...prev,label:response.data.title
          // }))
        }
      }
    } catch (error) {
      ToggleLoader(false);
      ShowToastMessage(ToastMessages.IssueOccured, ToastMessageType.Fail);
    }
  };

  useEffect(() => {
    GetAllProject();
    GetMiscellaneousTimeLog();
  }, []);

  const GetAllProject = async () => {
    ToggleLoader(true);

    const userRoleId = GetLocalStorageData("userRole")
    const userID = GetLocalStorageData("userId")

    let response = await ProjectService.GetAllProjectsByUserRole(userRoleId, userID);

    let projectNames = response.data && response.data.length > 0 && response.data.map((project) => ({
      value: project.id,
      label: project.name,
    }));

    const sortedProjectNames = projectNames && projectNames
      .slice()
      .sort((a, b) => a.label.localeCompare(b.label));

    if (response.status === HTTPResponse.OK) {
      setProjects(sortedProjectNames);
      ToggleLoader(false);
    }
    if (response.status === HTTPResponse.Unauthorized) {
      LogOut();
    }
  };

  /* <summary>
  date: 18-03-2024
  Name: Mustakim Shaikh
  description:to get Miscellaneous dropdown labels and its sub options
  <summary>*/
  const GetMiscellaneousTimeLog = async () => {
    const resp = await ReportService.GetMiscellaneousTimeLog();
    // const options = resp && resp.data && resp.data.map((category) => ({
    //   label: category.key,
    //   options: category.value.map((subcategory) => ({
    //     value: subcategory.value,
    //     label: subcategory.key,
    //   })),
    // }));

    // setMisc(options);
    const options = resp && resp.data && resp.data.map((category) => ({
      label: category.key,
      options: category.value.filter(subcategory => subcategory.value !== 17).map((subcategory) => ({
        value: subcategory.value,
        label: subcategory.key,
      })),
    }));
    
    const addNewMiscOption = {
      value: 17,
      label: <strong>Add New Misc Type</strong>,
    };
    
    const updatedOptions = [
      {
        label: '',
        options: [addNewMiscOption], 
      },
      ...options,  
    ];
    
    setMisc(updatedOptions);
  };

  useEffect(() => {
    if (selectedProject !== 0) {
      getActionItemForSpecificProjec();
    }
  }, [selectedProject]);

  const getActionItemForSpecificProjec = async () => {
    ToggleLoader(true);
    if (selectedProject.value > 0) {
      const resp = await ReportService.GetAllActionItems(selectedProject.value);
      const vare = resp.data.map((list) => ({
        value: list.actionItemId,
        label: list.key,
        title: list.title,
      }));
      setTaskIds(vare);

    }

    ToggleLoader(false);
  };

  useEffect(() => {
    const options = projects.map((lists) => ({
      value: lists.value,
      label: lists.label,
    }));
  }, [projects]);

  const handleSave = async () => {
    ToggleLoader(true);
    try {
      if (!Validation()) {

        let min, hrs;
        if (selectedTask.value > 0) {
          hrs = fieldValues.timeSpent.hours;
          min = fieldValues.timeSpent.mins;
        }
        else {
          hrs = data.timeSpent.hours;
          min = data.timeSpent.mins;
          //parseFloat(data.timeSpent)
        }
        //min = Number(min)
        // hrs = Number(hrs)
        const convertHHMMToDecimal = (hhmm) => {
          const [hours, minutes] = hhmm.split(':');
          // return hours + (minutes / 60);
          return `${hours}.${minutes}`
        };

        const formattedMinutes = min.padStart(2, '0');
        //const LoggedHours = convertHHMMToDecimal(`${hrs}:${formattedMinutes}`)
        const LoggedHours = convertHHMMToDecimal(`${hrs}:${formattedMinutes}`)
        let payLoad = {
          ReportDate: data.date,
          ProjectId: selectedProject.value > 0 ? selectedProject.value : 0,
          ActionItemId: selectedTask.value > 0 ? selectedTask.value : 0,
          EmployeeId: GetLoggedInUserID(),
          //TimeSpent: selectedTask.value > 0 ? parseFloat(fieldValues.timeSpent) : parseFloat(data.timeSpent),
          TimeSpent: LoggedHours,
          Details: selectedTask.value > 0 ? fieldValues.comments : data.comments,
          ///Details: data.comments,
          WorkLogDetailId: fieldValues.workLogDetailId > 0 ? fieldValues.workLogDetailId : 0,
          MISCTaskName: selectedTask.value > 0 ? "" : data.taskName,
          MiscellaneousTimeLogID: miscValue.value > 0 ? miscValue.value : 0,
          originalEstimate: fieldValues.originaleEst > 0 ? parseFloat(fieldValues.originaleEst) : 0,
          roleId: roleId
        };

        let response = await ReportService.SaveTimeLog(payLoad);
        //let response =payLoad;

        if (response.status == HTTPResponse.OK) {
          ShowToastMessage(fieldValues.workLogDetailId > 0 ? ToastMessages.UpdateReport : ToastMessages.SaveReport, ToastMessageType.Success);
          if (!fieldValues.workLogDetailId > 0) {
            setData((prev) => ({
              ...prev,
              timeSpent: "",
              comments: "",
              taskName: "",
            }));
            setErrors({
              TimeSpent: false,
              Project: false,
              Task: false,
              Comments: false,
              MiscDrp: false, TaskName: false
            });
            setSelectedProject({
              value: 0,
              label: "",
            });
            setSelectedTask({
              value: 0,
              label: "",
            });
            setMiscValue({
              value: 0,
              label: "",
            });
            setFieldValues({
              title: "", originaleEst: ""
            })
          }
        }
        ToggleLoader(false);
        // await fetchWLData();
      }
    } catch (error) {
      ToggleLoader(false);
      ShowToastMessage(ToastMessages.Fail, ToastMessageType.Fail);
    }

    ToggleLoader(false);
  };
  const NUMBER_REGE = /^\d*(\.\d{0,5})?$/s;
  const navigateToProject = () => {
    navigate("/TimeLog", {
      // state: {
      //     selectedFilters:selectedFilters
      // },
  });

  }


  const customStyles = {
    groupHeading: (provided) => ({
      ...provided,
      fontWeight: 'bolder',
      fontSize: '14px',
      color: 'black',
      fontFamily: 'Lato-Regular'
    }),
    option: (provided, state) => ({
      ...provided,
      ...(state.isSelected ? { marginLeft: '20px', fontSize: '12px', fontFamily: 'Lato-Regular' } : { marginLeft: '20px', fontSize: '12px', fontFamily: 'Lato-Regular' }), // Apply margin-left only if the option is not selected
    }),
  };




  const DropdownIndicator = (props) => {
    const { onMenuOpen, miscValue, selectedProject } = props;

    const handleIndicatorClick = (e) => {
      e.preventDefault();
      if (miscValue && miscValue.value > 0) {
        setMiscValue({ label: "", value: 0 });
      } else if (selectedProject && selectedProject.value > 0) {
        setSelectedProject({ label: "", value: 0 });
      } else {
        onMenuOpen();
      }
    };

    return (
      <div
        style={{ paddingRight: 6, marginLeft: 4, marginTop: -4 }}
        onMouseDown={handleIndicatorClick}
      >
        {(miscValue && miscValue.value > 0) || (selectedProject && selectedProject.value > 0) ? <FaTimes /> : <components.DropdownIndicator {...props} />}
      </div>
    );
  };
  const fetchWLData = async () => {
    try {
      ToggleLoader(true);
      let response = await ReportService.GetWorklogDetailById(employeeId, wrkLogId, roleId);
      const data = response.data;
      if (data !== null && data.length > 0) {
        let filteredData = data.map(item => ({
          employeeName: item.loggedByEmployee,
          date: item.loggedOn ? item.loggedOn : '',
          loggedDate: item.loggedDate ? item.loggedDate : '',
          hours: item.loggedHours || 0,
          project: item.projectName || '',
          taskId: item.key || '',
          taskName: item.title || '',
          loggedBy: item.loggedBy,
          projectID: item.projectID,
          comment: item.comments != "" ? stripHTMLTags(item.comments) : "",
          miscellaneousTimeLogId: item.miscellaneousTimeLogId,
          misctaskType: item.misctaskType,
          miscMisctaskName: item.miscMisctaskName,
          workLogDetailID: item.workLogDetailID,
          miscName: item.miscName,
          workLogTaskID: item.taskID,
          loggedDate: item.loggedOn ? item.loggedOn : new Date(),
          originalEstimate: item.originalEstimate,
        }));
        let existingTimeLogDetailsState = filteredData[0];
        let projectDetails = {
          label: filteredData[0].project,
          value: filteredData[0].projectID
        }
        setWorkLogId(filteredData[0]?.workLogDetailID > 0 ? filteredData[0]?.workLogDetailID : 0);
        setAddEditReportData((prev) => ({
          ...prev,
          date: filteredData[0].date,
          selectedProject: projectDetails,
          reportId: filteredData[0]?.workLogDetailID,
          projectId: filteredData[0].projectID,
          timeSpent: filteredData[0].hours,
        }));

        setSelectedProject((prev) => ({
          ...prev,
          label: filteredData[0].project,
          value: filteredData[0].projectID
        }));

        setMiscValue((prev) => ({
          ...prev,
          value: filteredData[0].miscellaneousTimeLogId > 0 ? filteredData[0].miscellaneousTimeLogId : 0,
          label: filteredData[0].miscellaneousTimeLogId > 0 ? filteredData[0].miscName : ""
        }));

        setSelectedTask((prev) => ({
          ...prev,
          value: filteredData[0].workLogTaskID > 0 ? filteredData[0].workLogTaskID : 0,
          label: filteredData[0].taskId,
        }));

        setFieldValues((prev) => ({
          ...prev,
          title: filteredData[0].taskName,
          originaleEst: filteredData[0].originalEstimate,
          timeSpent: filteredData[0].hours,
          comments: filteredData[0].comment != "" ? stripHTMLTags(filteredData[0].comment) : "",
          workLogDetailId: filteredData[0]?.workLogDetailID
        }));

        setData((prev) => ({
          ...prev,
          date: new Date(filteredData[0].loggedDate),
          timeSpent: filteredData[0].hours,
          comments: filteredData[0].comment != "" ? stripHTMLTags(filteredData[0].comment) : "",
          taskName: filteredData[0].taskName,
        }));

        ToggleLoader(false);
      } else {
        if (response.status === HTTPResponse.Unauthorized) {
          LogOut();
        }
        ToggleLoader(false);
      }
    } catch (error) {
      ShowToastMessage(ToastMessages.Fail, ToastMessageType.Fail);
    }
  };


  const closeMiscModal = () => {
    setIsMisOtherModalOpen(false);
    GetMiscellaneousTimeLog();
  }
  // const handleTimeChange = (e) => {
  //   const { id, value } = e.target;

  //   if (value >= 0 && NUMBER_REGE.test(value)) {
  //     setData((prev) => ({
  //       ...prev,
  //       timeSpent: {
  //         ...prev.timeSpent,
  //         [id]: value,
  //       },
  //     }));

  //     setFieldValues((prev) => ({
  //       ...prev,
  //       timeSpent: {
  //         ...prev.timeSpent,
  //         [id]: value,
  //       },
  //     }));

  //     setErrors((prev) => ({
  //       ...prev,
  //       TimeSpent: false,
  //     }));
  //   }
  // };

  const handleTimeChange = (e) => {
    const { id, value } = e.target;

    if (NUMBER_REGE.test(value)) {

      const numericValue = parseInt(value, 10);
      if (isNaN(numericValue)) return;


      var newValue
      if (id === 'hours' && numericValue >= 0 && numericValue <= 99) {
        newValue = numericValue.toString().padStart(2, '0');
        setData((prev) => ({
          ...prev,
          timeSpent: {
            ...prev.timeSpent,
            [id]: newValue,
          },
        }));
        setFieldValues((prev) => ({
          ...prev,
          timeSpent: {
            ...prev.timeSpent,
            [id]: newValue,
          },
        }));
        setErrors((prev) => ({
          ...prev,
          TimeSpent: false,
        }));
      } else if (id === 'mins' && numericValue >= 0 && numericValue <= 59) {
        newValue = numericValue.toString().padStart(2, '0');
        setData((prev) => ({
          ...prev,
          timeSpent: {
            ...prev.timeSpent,
            [id]: newValue,
          },
        }));
        setFieldValues((prev) => ({
          ...prev,
          timeSpent: {
            ...prev.timeSpent,
            [id]: newValue,
          },
        }));
        setErrors((prev) => ({
          ...prev,
          TimeSpent: false,
        }));
      }

    }
  };
  const handleHoursFocus = (e) => {
    e.target.select(); // Selects the entire content of the input field
  };
  /* <summary>
  date: 24-06-2024
  Name: Mustakim 
  description: React-select was giving options not matched with searched labels, hence created custom filter.
  <summary>*/
  const customFilter = (option, inputValue) => {
    return option.label.toLowerCase().includes(inputValue.toLowerCase())
  };
  return (
    <>
      <main style={{ minHeight: "85vh" }}>

        <section className="breadcrumb-block">
          <BreadcrumbComponent />
        </section>
        <AddMiscellenousOther
          isOpen={isMisOtherModalOpen}
          otherMisType={miscSelectedlabelToOthersModal}
          setShowModal={closeMiscModal}
          isTrainingOtherSelcted={isTrainingMiscOther}
          isMiscOtherSelected={isMiscOther}
        />
        {showDeleteModalPopUp && (
          <DeleteModal
            showDeleteModal={showDeleteModalPopUp}
            onYesClick={OnDeleteModalYesClick}
            onNoClick={OnCloseDeleteModal}
            textToDisplay={"Are you sure you want to delete?"}
          />
        )}
        {/* <section className="title-block">
          <div className="container">
            <div className="title-f-box">
              <div className="title-banner">
                <h3 className="page-title">
                  {wrkLogId > 0 ? "Edit Time Log" : "Add Time Log"}
                </h3>
              </div>
            </div>
          </div>
        </section> */}
        {/* <section className="breadcrumb-block">
        <BreadcrumbComponent projectName={projectName} />
        <div className="container">
          <div className="nav-breadcrumb"></div>
        </div>
      </section> */}



        <section className="add-time-log-body">
          <div className="container-fluid">
            <div className="add-time-log">
              <div className="row">
                <div className="col-12 col-md-6 col-lg-6 mt-2 custom-padding">
                  <label>
                    Date <span className="mandatory-field">*</span>
                  </label>
                  <div>
                    <label style={{ display: 'block' }}>
                      <DatePicker

                        className=" form-control "
                        //disabled={editMode}

                        dateFormat="dd-MM-yyyy"
                        selected={data.date}
                        onChange={(date) =>
                          setData((prev) => ({
                            ...prev,
                            date: date,
                          }))
                        }
                        showIcon={true}
                        onKeyDown={(e) => {
                          e.preventDefault();
                        }}
                        maxDate={new Date()}
                        popperClassName="ag-custom-component-popup"
                        scrollableYearDropdown={true}
                        showYearDropdown
                        showMonthDropdown
                        todayButton="Today"
                      />{" "}
                    </label>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-6 mt-2 custom-padding">
                  <label>
                    Time Spent (Hours : Mins) <span className="mandatory-field">*</span>
                  </label>
                  
                  <div className="log-hours-main">
                    <div className="hrsMin" style={{ backgroundColor: '#fff', width: "100%" }}>
                      <input
                        style={{ width: '26px' ,height:'28px'}}
                        type="number"
                        id="hours"
                        min={0}
                        max={99}
                        onFocus={handleHoursFocus} // Added onFocus event handler

                        onChange={handleTimeChange}
                        //      value={selectedTask.value > 0 ? (fieldValues.timeSpent?.hours || "00") : (data.timeSpent?.hours || "00")}

                        value={selectedTask.value > 0 ? (fieldValues.timeSpent ? fieldValues.timeSpent.hours : "00") : data.timeSpent ? data.timeSpent.hours : "00"}
                      />
                      <span style={{ marginRight: '8px' }}>:</span>
                      <input
                        style={{ width: '26px',height:'28px' }}
                        type="number"
                        id="mins"
                        min={0}
                        max={59}
                        step="15"
                        onChange={handleTimeChange}
                        onFocus={handleHoursFocus} // Added onFocus event handler

                        //value={selectedTask.value > 0 ? fieldValues.timeSpent?fieldValues.timeSpent.mins:"0" :(data.timeSpent? data.timeSpent.mins:"0")}
                        // value={selectedTask.value > 0 ? (fieldValues.timeSpent?.mins || "00") : (data.timeSpent?.mins || "00")}
                        value={selectedTask.value > 0 ? (fieldValues.timeSpent ? fieldValues.timeSpent.mins : "00") : data.timeSpent ? data.timeSpent.mins : "00"}

                      />
                    </div></div>

                  <ErrorField
                    show={errors.TimeSpent}
                    message={CommonErrorMessages.TimeSpent}
                  />
                </div>
              </div>
              <div className="row" style={{ alignItems: "end" }}>
                <div className="col-12 col-md-6 col-lg-6 mt-2 custom-padding">
                  <label>
                    Project <span className="mandatory-field">*</span>
                  </label>
                  <Select
                    name="projectList"
                    isDisabled={miscValue.value || wrkLogId > 0}
                    value={selectedProject.value > 0 ? selectedProject : null}
                    placeholder="Please Select"
                    options={projects}

                    //components={{ DropdownIndicator: (props) => <DropdownIndicator {...props} selectedProject={selectedProject} /> }} // Pass selectedProject as a prop

                    isClearable={selectedProject !== undefined || (!selectedProject === null && selectedProject?.value > 0)}
                    // components={{ DropdownIndicatorProject: (props) => <DropdownIndicatorProject {...props} selectedProject={selectedProject} /> }} // Pass selectedProject as a prop

                    onChange={(e) => {
                      if (e === null) {
                        setSelectedProject({
                          value: 0,
                          label: "",
                        });
                      }
                      else {
                        setSelectedProject(e);
                        setFieldValues({
                          originaleEst: 0,
                          title: "",
                        });
                        setSelectedTask({ label: "", value: 0 });
                        setErrors((prev) => ({
                          ...prev,
                          Project: false,
                          MiscDrp: false,
                        }));
                      }
                    }
                    }
                  />
                  <ErrorField
                    show={errors.Project}
                    message={CommonErrorMessages.SelectProject}
                  />
                  <span className="or-text">OR</span>
                </div>

                <div className="col-12 col-md-6 col-lg-6 mt-2 custom-padding">
                  <label>Miscellaneous Type <span className="mandatory-field">*</span></label>
                  <Select
                    name="miscList"
                    isDisabled={selectedProject.value > 0}
                    value={miscValue.value > 0 ? miscValue : null}
                    placeholder="Please Select"
                    options={misc}
                    styles={customStyles}
                    //  value={miscValue} // Set the selected value
                    onChange={handleMiscChange} // Call the handler function on change

                    //components={{ DropdownIndicator }}
                    // omponents={{ DropdownIndicator: (props) => <DropdownIndicator {...props} miscValue={miscValue} /> }} // Pass selectedProject as a prop
                    isClearable={miscValue !== undefined || (!miscValue === null && miscValue?.value > 0)}
                    components={{ Option: (props) => <CustomOption {...props} onDeleteOption={handleDeleteOption} /> }} //SYJ - Custome Options to add Delete Functionality

                  />
                  <ErrorField
                    show={errors.MiscDrp}
                    message={ReportErrorMessage.Misc}
                  />
                </div>
              </div>
              <div className="row">
                {selectedProject.value > 0 && (
                  <div className="col-12 col-md-2 col-lg-3 mt-2 custom-padding">
                    <label>
                      Task ID <span className="mandatory-field">*</span>
                    </label>
                    <Select
                      name="TaskIds"
                      placeholder="Please Select"
                      options={TaskIds}
                      isDisabled={wrkLogId > 0}
                      value={selectedTask.value > 0 ? selectedTask : null}
                      filterOption={customFilter}
                      onChange={(e) => {
                        if (e === null) {
                          setSelectedTask({
                            value: 0,
                            label: "",
                          });
                        }
                        else {
                          setSelectedTask((prev) => ({
                            ...prev,
                            label: e.label,
                            value: e.value,
                          }));
                          // setIsEditLoadData(false);
                          setErrors((prev) => ({
                            ...prev,
                            Task: false,
                          }));
                        }
                      }}
                      isClearable={selectedTask !== undefined || (!selectedTask === null && selectedTask?.value > 0)}
                    />
                    <ErrorField
                      show={errors.Task}
                      message={ReportErrorMessage.Task}
                    />

                  </div>
                )}
                {selectedProject.value > 0 && selectedTask.value > 0 && (
                  <div className="col-12 col-md-4 col-lg-3 mt-2 custom-padding">
                    <label>Orginal Estimate (hrs)</label>
                    <div className="time-spent-fbox">
                      <input
                        className="form-control"
                        type="text"
                        value={fieldValues.originaleEst}
                        //disabled
                        onChange={(e) => {
                          const inputValue = e.target.value;
                          if (inputValue >= 0 && NUMBER_REGE.test(inputValue)) {


                            setFieldValues((prev) => ({
                              ...prev, originaleEst: e.target.value,
                            }));

                            setErrors((prev) => ({
                              ...prev,
                              TimeSpent: false,
                            }));
                          }
                        }
                        }
                      />
                      <span className="hrs-span">hrs</span>
                    </div>
                  </div>
                )}
              </div>
              <div className="row">
                {

                 ( selectedTask.value > 0 || miscValue.value > 0 )&& (
                    <div className="col-12 col-md-12 col-lg-12 mt-2 custom-padding">
                      <label>
                        Task Name <span className="mandatory-field">*</span>
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        value={fieldValues ? fieldValues.title : data.taskName}
                        onChange={(e) => {
                          setFieldValues((prev) => ({
                            ...prev,
                            title: e.target.value
                          }));
                          setErrors((prev) => ({
                            ...prev,
                            TaskName: false,
                          }));
                          setData((prev) => ({
                            ...prev,
                            taskName: e.target.value
                          }));
                        }}
                      />
                      <ErrorField
                        show={errors.TaskName}
                        message={ReportErrorMessage.TaskName}
                      />
                    </div>
                  )
                }


                <div className="col-12 col-md-12 col-lg-12 mt-2 custom-padding">
                  <label>
                    Comments <span className="mandatory-field">*</span>
                  </label>
                  <textarea
                    className="form-control"
                    rows="4"
                    value={selectedTask.value > 0 ? fieldValues.comments : data.comments}
                    onChange={(e) => {
                      if (selectedTask.value === 0) {
                        setData((prev) => ({
                          ...prev,
                          comments: e.target.value,
                        }));
                      }
                      else {
                        setFieldValues((prev) => ({
                          ...prev, comments: e.target.value
                        }))
                      }

                      setErrors((prev) => ({
                        ...prev,
                        Comments: false,
                      }));
                    }}
                  ></textarea>
                  <ErrorField
                    show={errors.Comments}
                    message={ReportErrorMessage.Description}
                  />
                </div>
              </div>

              <div className="save-cancel-btn mt-3">
                <button className="save-btn custom-width" onClick={handleSave}>
                  Save
                </button>

                <button className="cancel-btn custom-width" onClick={navigateToProject}>Cancel</button>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default AddEditReport;
